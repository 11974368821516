<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
            </b-button> -->
        <router-link class="btn btn-outline-info" to="sales-order" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Sales Order
        </router-link>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Detail Sales Order</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">

                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No. Sales Order</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_so" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No. Purchase Order</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_po" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Customer</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.customer_name" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3" v-if="formInput.no_pr != ''">
                                <label class="text text-info">No Purchase Request</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_pr" />
                            </div>
                        </div>
                        <hr class="pb-4 mt-4">
                        <div class="form-row">
                            <div class="pt-4 table-responsive" v-if="shelter.length > 0">
                                <table class="table dark-table-bordered text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>QTY</th>
                                            <th>Stock Available</th>
                                            <th>Safety Stock</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in shelter" :key="index" :class="value.qty > value.stock_available ? 'table-danger' : 'table-success' ">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }} <span v-if="value.is_bonus == 1" class="badge badge-xs badge-info">Bonus</span></td>
                                            <td>{{ value.qty }}</td>
                                            <td>{{ value.stock_available }}</td>
                                            <td>{{ value.safety_stock }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <!-- <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p> -->
                    </div>
                    <div class="mt-4">
                        <!-- <b-button title="Create Purchase Request" class="mr-1" :to="'todo-purchase-request?id='+ $route.query.id" variant="outline-info"><i class="fa fa-plus mr-1"></i> Create Purchase Request </b-button> -->
                        <b-button title="Create Delivery Order" class="mr-1" :to="'todo-delivery-order?id='+ $route.query.id" variant="outline-primary"><i class="fa fa-plus mr-1"></i> Create Delivery Order </b-button>
                        <!-- <button @click="handleSubmit" class="btn btn-outline-info mb-1 mr-1"><i class="fa fa-plus mr-1"></i> Todo Purchase Request</button> -->
                        <!-- <button @click="handleSubmit" class="btn btn-outline-primary mb-1"><i class="fa fa-plus mr-1"></i> Todo Delivery Order</button> -->
                    </div>
                    <!-- <div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "DetailSalesOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Sales Order | %s',
    },
    data() {
        return {
            selected: [],
            products: [],
            sales_order: [],
            formInput: {
                no_po: '',
                no_so: '',
                no_pr: '',
                id_sales_order: '',
                customer_name: '',
                products: []
            },
            shelter: [],
        };
    },
    async created() {
        this.onLoad()
    },
    // computed: {
    //     filterProduct: function () {
    //         return this.products.filter(i => i.udpate_by == null)
    //     },
    // },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('sales-order');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/sales_order/wh/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.id_sales_order = resp.data.data.id;
                    this.formInput.no_so = resp.data.data.no_so;
                    this.formInput.no_po = resp.data.data.no_po;
                    this.formInput.customer_name = resp.data.data.customer.organization;
                    this.products = resp.data.data.get_product_sos;
                    this.formInput.no_pr = resp.data.data.pr == null ? '' : resp.data.data.pr.no_pr;

                    // each every products
                    await resp.data.data.get_product_sos.concat(resp.data.data.bonus_product).forEach(function (value, index) {
                        if (value.approved == 1) {
                            if (index == 0) {
                                arr.push({
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    stock_available: value.stock_available,
                                    safety_stock: value.safety_stock,
                                    qty: 1,
                                    is_bonus: value.is_bonus
                                });
                            } else {

                                let found = false;

                                for (let i = 0; i < arr.length; i++) {
                                    const element = arr[i];
                                    if (element.part_number == value.part_number && element.is_bonus == value.is_bonus) {
                                        found = true;
                                        arr[i].qty += 1;
                                        break;
                                    }
                                }

                                if (found === false) {
                                    arr.push({
                                        part_number: value.part_number,
                                        product_name: value.product_name,
                                        stock_available: value.stock_available,
                                        safety_stock: value.safety_stock,
                                        qty: 1,
                                        is_bonus: value.is_bonus
                                    });
                                }
                            }
                        }
                    });
                    this.shelter = arr.sort((a, b) => a.is_bonus - b.is_bonus);

                    return this.sales_order = resp.data.data;
                }

                return this.$router.push('SalesOrder');
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     this.$toastr.e(
                //         error.response.data.message,
                //         "Request Sales Order"
                //     );
                //     return this.$router.push('SalesOrder');
                // }
                // this.$toastr.e(error, "Request Sales Order");
                // return this.$router.push('SalesOrder');
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Sales Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Order ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        onRowSelected(items) {
            this.selected = items
        },
        async handleSubmit() {

            // if (!this.formInput.no_so) {
            //     this.$swal.fire("Error", 'The No Sales Order field can not be empty', "error");
            //     return;
            // }

            this.formInput.products = this.selected;

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/sales_order/wh/todo_pr",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    // await this.$bvModal.hide("modal-add");
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.products = [];
                    this.formInput = {
                        no_pr: '',
                        no_so: '',
                        id_sales_order: '',
                        customer_name: '',
                        products: []
                    };
                }
            } catch (error) {
                // console.log(error);
                // this.$swal.fire("Error", error.response.data.message, "error");
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
