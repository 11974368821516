<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <b-button variant="outline-info" @click="filterData = !filterData">
            Filter Data
            <b-icon-arrow-bar-down></b-icon-arrow-bar-down>
        </b-button>
        <div class="mr-auto d-none d-lg-block">
        </div>
        <!-- <b-button title="Product Record" class="mr-2" variant="outline-success" v-b-modal.modal-expired>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-telegram mr-2" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
            </svg>
            Expired Product Record
        </b-button> -->
        <div class="dropdown custom-dropdown mr-2">
            <button type="button" class="btn btn-outline-primary d-flex align-items-center svg-btn" data-toggle="dropdown" aria-expanded="false" style="border-color: #2f4cdd !important">
                <svg width="16" class="scale5" height="16" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16647 27.9558C9.25682 27.9856 9.34946 28.0001 9.44106 28.0001C9.71269 28.0001 9.97541 27.8732 10.1437 27.6467L21.5954 12.2248C21.7926 11.9594 21.8232 11.6055 21.6746 11.31C21.526 11.0146 21.2236 10.8282 20.893 10.8282H13.1053V0.874999C13.1053 0.495358 12.8606 0.15903 12.4993 0.042327C12.1381 -0.0743215 11.7428 0.0551786 11.5207 0.363124L0.397278 15.7849C0.205106 16.0514 0.178364 16.403 0.327989 16.6954C0.477614 16.9878 0.77845 17.1718 1.10696 17.1718H8.56622V27.125C8.56622 27.5024 8.80816 27.8373 9.16647 27.9558ZM2.81693 15.4218L11.3553 3.58389V11.7032C11.3553 12.1865 11.7471 12.5782 12.2303 12.5782H19.1533L10.3162 24.479V16.2968C10.3162 15.8136 9.92444 15.4218 9.44122 15.4218H2.81693Z" fill="#2F4CDD" />
                </svg>
                <span class="fs-16 ml-3">Toggle Colums</span>
                <i class="fa fa-angle-down scale5 ml-3"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <button v-for="(column, index) in columnRender" :key="index" @click="changeColumn(column)" :class="
              column.hidden
                ? 'btn btn-xs btn-outline-warning btn-block'
                : 'btn btn-xs btn-outline-secondary btn-block'
            ">
                    {{ column.label }}
                </button>
            </div>
        </div>
    </div>
    <div v-if="filterData == true" class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Filter Data</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text">Principle</label>
                                <v-select label="name" :filterable="false" :options="principles" v-model="paramQuery.id_principle" :reduce="principle => principle.id" @search="onSearchPrinciple">
                                    <template slot="no-options"> type to search principle... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            <img :src='option.logo' style="width: 1rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Category</label>
                                <v-select label="name" :filterable="false" :options="categories" v-model="paramQuery.id_category" :reduce="categories => categories.id" @search="onSearchCategory">
                                    <template slot="no-options"> type to search categories... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            <img :src='option.logo' style="width: 1rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">Brand</label>
                                <v-select label="name" :filterable="false" :options="brands" v-model="paramQuery.id_brand" :reduce="brand => brand.id" @search="onSearchBrand">
                                    <template slot="no-options"> type to search brand... </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            <img :src='option.logo' style="width: 1.5rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            <img :src='option.logo' style="width: 1rem;height: auto;" />
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3">

                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text text-info">Start Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.start_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text text-info">End Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.end_date" :min="paramQuery.start_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-12 col-lg-12">
                                <b-button variant="outline-info" @click="getData">
                                    Filter
                                </b-button>
                                <b-button class="ml-3" variant="outline-danger" @click="clearInput">
                                    Clear
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Data Expired Product</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'fullname'">
                                <div class="d-flex align-items-center">
                                    <img :src="props.row.profile" class="rounded-lg mr-2" width="24" alt="" />
                                    <span class="w-space-no">{{props.row.fullname}}</span>
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'origin_stock'">
                                {{props.row.origin_stock}}
                            </div>
                            <div v-else-if="props.column.field == 'current_stock'">
                                {{props.row.current_stock}}
                            </div>
                            <div v-else-if="props.column.field == 'piece'">
                                <span class="badge badge-rounded badge-primary">{{props.row.piece}}</span>
                            </div>
                            <!-- <div v-else-if="props.column.field == 'action'">
                                <b-button title="Edit" variant="outline-info" pill size="sm" @click="propsEdit = props.row" v-b-modal.modal-edit><i class="fa fa-pencil"></i></b-button>
                                &nbsp;
                                <b-button title="Delete" variant="outline-danger" pill size="sm" @click="deleteData(props.row)"><i class="fa fa-trash"></i></b-button>
                            </div> -->
                            <div v-else-if="props.column.field == 'action'">
                                <!-- <b-button title="Product Record" :to="'product-record?id='+ props.row.id + '&id_brand='+ props.row.id_brand" variant="outline-success" size="sm"><b-icon-journal></b-icon-journal> Record </b-button> -->
                                <b-button title="Product Record" variant="outline-success" size="sm" @click="propsEdit = props.row" v-b-modal.modal-export>
                                    <b-icon-journal></b-icon-journal> Record
                                </b-button>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import {
    BButton,
} from "bootstrap-vue";
const moment = require("moment");

export default {
    name: "ExpiredRecord",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Expired product | %s',
    },
    components: {
        BButton
    },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Name",
                    field: "name",
                    hidden: false,
                },
                {
                    label: "Principle Name",
                    field: "principle_name",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Category Name",
                    field: "category_name",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Brand",
                    field: "brand_name",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Stock",
                    field: "stock",
                    type: 'number',
                    hidden: false,
                    sortable: false,
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: "Unit",
                    field: "piece",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center",
                },
                {
                    label: "Expired Date",
                    field: "expired_date",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-danger",
                },
                // {
                //     label: "Action",
                //     field: "action",
                //     hidden: false,
                //     thClass: "text-center",
                //     tdClass: "text-center",
                //     sortable: false,
                //     width: '110px',
                // },
            ],
            filterData: false,
            principles: [],
            categories: [],
            brands: [],
            rows: [],
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_principle: "",
                id_category: "",
                id_brand: "",
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date,
            },
            formInput: {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            }
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i =>
                i.field != 'action' &&
                i.field != 'principle_name' &&
                i.field != 'category_name' &&
                i.field != 'current_stock' &&
                i.field != 'origin_stock' &&
                i.field != 'brand_name'
            )
        },
    },
    async created() {
        this.getData();
    },
    methods: {
        clearInput() {
            this.paramQuery = {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_principle: "",
                id_product: "",
                start_date: "",
                end_date: "",
            }
            this.getData();
        },

        handleCancel() {
            this.propsEdit = {};
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async onSearchPrinciple(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/principle", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principles = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onSearchCategory(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/category", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.categories = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Category", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Category ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onSearchBrand(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/brand", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.brands = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Brand", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Brand ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/record/product/expired", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
