var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paramQuery.search),expression:"paramQuery.search"}],staticClass:"form-control w-50",attrs:{"type":"text","placeholder":"Search..."},domProps:{"value":(_vm.paramQuery.search)},on:{"keyup":_vm.onSearchChange,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.paramQuery, "search", $event.target.value)}}})]),_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"display mb-4 dataTable no-footer","totalRows":_vm.totalRecords,"pagination-options":{
                                    enabled: true,
                                    dropdownAllowAll: false,
                                    setCurrentPage: _vm.paramQuery.page,
                                    nextLabel: 'Next',
                                    prevLabel: 'Previous',
                                    setCurrentPage: _vm.paramQuery.page,
                                },"rows":_vm.rows,"columns":_vm.columns,"compactMode":""},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'latitude')?_c('div',[_vm._v(" "+_vm._s(props.row.latitude)+" – "+_vm._s(props.row.longitude)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }