<template>
<div>
    <div class="input-group">
        <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
    </div>
    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                    enabled: true,
                                    dropdownAllowAll: false,
                                    setCurrentPage: paramQuery.page,
                                    nextLabel: 'Next',
                                    prevLabel: 'Previous',
                                    setCurrentPage: paramQuery.page,
                                }" :rows="rows" :columns="columns" compactMode>
        <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field == 'latitude'">
                {{ props.row.latitude }} &ndash; {{ props.row.longitude }}
            </div>

            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </template>
    </vue-good-table>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "RowTable",
    props: {
        idRow: {
            type: String
        }
    },
    watch: {
        idRow: {
            immediate: true,
            handler() {
                this.id_row = this.idRow
            }
        }
    },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Product Name",
                    field: "name",
                    hidden: false,
                },
                {
                    label: "Exp Date",
                    field: "exp_date",
                    hidden: false,
                },
                {
                    label: "Serial Number",
                    field: "serial_number",
                    hidden: false,
                },
            ],
            rows: [],
            totalRecords: 0,
            id_row: '',
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_warehouse: this.$route.query.id
            },
        }
    },
    async created() {
        this.getData()
    },
    methods: {

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            if (this.paramQuery.search.length > 2) {
                this.updateParams({
                    page: 1
                });
                this.getData();
            }
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/management_inventory/product_list", {
                    id_warehouse_rack_row: this.id_row,
                    ...this.paramQuery
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
