<template>
<div>
    <b-modal id="modal-export" ref="my-modal-export" title="Product Record" size="xl" ok-disabled cancel-disabled ok-variant="hidden" ok-title="" cancel-variant="hidden" cancel-title="" centered no-close-on-esc no-close-on-backdrop button-size="sm">
        <form ref="formRecord" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">From</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.start_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-6 col-lg-6">
                    <label class="text text-info">To</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.end_date" :min="formInput.start_date">
                    </b-form-datepicker>
                </div>
            </div>
        </form>
        <b-button-group v-if="formInput.start_date !== null && formInput.end_date !== null">
            <b-button title="Product Record" :to="'product-record?id='+ formInput.id + '&start_date='+ formInput.start_date + '&end_date='+ formInput.end_date" variant="outline-success" size="sm"> Find Product Record </b-button>
        </b-button-group>
        <b-button-group v-else>
            <b-button disabled title="Product Record" variant="outline-success" size="sm"> Find Product Record </b-button>
        </b-button-group>
    </b-modal>
</div>
</template>

<script>
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "ProductRecord",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput = Object.assign({}, this.propsRow);
            },
        },
    },
    data() {
        return {
            products: [],
            formInput: {

            },
        };
    },
    methods: {
        
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
