<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Receive Item</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No Delivery Order</label> <small class="text text-primary">(Supplier)</small>
                                <input type="text" class="form-control" placeholder="Enter the delivery order" required v-model="formInput.no_do_in" />
                            </div>
                            <div v-if="do_data.length == 0" class="form-group col-md-6 col-lg-3">
                                <label class="text text-info text-white">-</label>
                                <b-button class="form-control" variant="outline-info" @click="searchDO">
                                    <i class="fa fa-search mr-1"></i>Search Delivery Order
                                </b-button>
                            </div>
                            <template v-if="do_data.length != 0">
                                <!-- <template> -->
                                <div v-if="Object.keys(do_data[0]).length === 0" class="form-group col-md-6 col-lg-3">
                                    <label class="text text-info">No Purchase Order</label>
                                    <v-select label="no_po" :filterable="false" v-model="no_po" :options="purchase_orders" @search="onSearchPurchaseOrder" @input="setSelectedPurchaseOrder">
                                        <template slot="no-options"> type to search purchase order... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.no_po }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.no_po }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-md-6 col-lg-3">
                                    <label class="text text-info">No Purchase Request </label>
                                    <input type="text" v-model="formInput.no_pr" class="form-control" disabled readonly>
                                </div>
                                <div class="form-group col-md-6 col-lg-3" :hidden="true">
                                    <label class="text text-info">No Invoice In </label>
                                    <input type="text" v-model="formInput.no_invoice_in" class="form-control" disabled readonly>
                                </div>
                                <div v-if="Object.keys(do_data[0]).length === 0" class="form-group col-md-6 col-lg-3">
                                    <label class="text text-info">No Sales Order</label>
                                    <input type="text" v-model="formInput.no_so" class="form-control" disabled readonly>
                                </div>
                                <!-- <div class="form-group col-md-6 col-lg-3">
                                <label class="text text-info">No Delivery Order</label> <small class="text text-primary">(Supplier)</small>
                                <input type="text" class="form-control" placeholder="Enter the delivery order" required v-model="formInput.no_do_in" />
                            </div> -->
                                <div class="form-group col-md-4 col-lg-3">
                                    <label class="text text-info">Warehouse</label>
                                    <v-select label="name" :filterable="false" :options="warehouse" v-model="formInput.id_warehouse" :reduce="warehouse => warehouse.id" @search="onSearchWarehouse" @input="setSelectedWarehouse">
                                        <template slot="no-options"> type to search warehouse... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                                <div class="form-group col-md-4 col-lg-3">
                                    <label class="text text-info">Incoming Date</label>
                                    <b-form-datepicker no-flip :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" locale="id" v-model="formInput.incoming_date" style="border-width: initial; height: 56px !important;" :max="new Date()"></b-form-datepicker>
                                </div>
                            </template>
                        </div>
                        <hr class="mb-3 mt-4">
                        <div class="form-row" v-if="products.length > 0">
                            <div class="form-group col-md-3 col-lg-2">
                                <div class="custom-control custom-checkbox mt-5">
                                    <input type="checkbox" class="custom-control-input" id="customCheckBox1" @click="handleSelectAll($event)" ref="selectAll">
                                    <label class="custom-control-label" for="customCheckBox1">Select All</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Default Exp Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }" locale="id" @input="defaultDate" v-model="defaultExpDate" style="border-width: initial; height: 56px !important;"></b-form-datepicker>
                            </div>
                            <div class="form-group col-md-4 col-lg-3">
                                <label class="text text-info">Default Rack</label>
                                <v-select :options="racks" label="name" @input="defaultRack" v-model="defaultRackSelect">
                                    <template slot="no-options"> please select warehouse first </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="form-row mt-4" v-if="products.length > 0">
                            <b-table bordered outlined hover fixed head-variant="dark" ref="selectableTable" selectable select-mode="multi" :items="products" :fields="fields" @row-selected="onRowSelected">
                                <!-- Example scoped slot for select state illustrative purposes -->
                                <template #cell(no)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Selected</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Not selected</span>
                                    </template>
                                </template>
                                <template #cell(exp_date)="row">
                                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="row.item.exp_date">
                                    </b-form-datepicker>
                                </template>
                                <template #cell(rack)="row">
                                    <v-select :options="racks" label="name" @input="(rack) => handleRack(rack, row.item)" v-model="row.item.id_warehouse_rack_name">
                                        <template slot="no-options"> please select warehouse first </template>
                                    </v-select>
                                </template>
                                <template #cell(rack_row)="row">
                                    <v-select :options="row.item.racks_row" label="name" @input="(rack_row) => handleRackRow(rack_row, row.item)" v-model="row.item.id_warehouse_rack_row_name">
                                        <template slot="no-options"> please select rack first </template>
                                    </v-select>
                                </template>
                                <template #cell(serial_number)="row">
                                    <input text="text" class="form-control" placeholder="optional" v-model="row.item.serial_number">
                                </template>
                            </b-table>
                            <!-- <b-pagination v-model="currentPage" :total-rows="row.length" :per-page="perPage" aria-controls="my-table"></b-pagination> -->
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center" v-if="products.length > 0">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <p class="card-text text-dark d-inline">Total Item: {{ products.length || 0 }} <br> Total Selected Item: {{ selected.length || 0 }} </p>
                    </div>

                    <button @click="handleSubmit" class="btn btn-outline-success" :disabled="selected.length == 0"><i class="fa fa-save mr-1"></i> {{ selected.length == 0 ? 'PLEASE SELECT PRODUCT' : 'SAVE TO STOCK' }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "ReceiveItem",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Receive Item | %s',
    },
    props: {
        pieces: {
            type: Array,
        },
    },
    data() {
        return {
            perPage: 3,
            currentPage: 1,
            fields: [{
                    key: "no",
                    label: "No",
                    thStyle: {
                        width: "4%"
                    },
                    class: 'text-center'
                },
                'part_number', 'product_name', 'rack', 'rack_row', 'exp_date', 'serial_number', 'selected'
            ],
            selected: [],
            warehouse: [],
            purchase_order: [],
            purchase_orders: [],
            products: [],
            racks: [],
            racks_row: [],
            do_data: [],
            defaultExpDate: '',
            defaultRackSelect: '',
            defaultRow: '',
            no_po: '',
            id_pr: '',
            formInput: {
                no_po: '',
                no_pr: '',
                no_so: '',
                no_do_in: '',
                no_invoice_in: '',
                id_warehouse: '',
                id_warehouse_rack: '',
                id_warehouse_rack_row: '',
                incoming_date: '',
                products: []
            },
        };
    },
    // computed: {
    //     filterProduct: function () {
    //         return this.products.filter(i => i.udpate_by == null)
    //     },
    // },
    methods: {
        async handleSelectAll($event) {
            if ($event.target.checked) {
                await this.$refs.selectableTable.selectAllRows();
            } else {
                await this.$refs.selectableTable.clearSelected();
            }
        },
        async defaultDate(date) {
            await this.products.map((val) => {
                val.exp_date = date;
            });
            this.bugTableSelected();
        },
        async defaultRack(rack) {
            if (rack == null) {
                return;
            }

            await this.products.map((val) => {
                val.racks_row = rack.rack_row;
                val.id_warehouse_rack = rack.id;
                val.id_warehouse_rack_name = rack.name;
                delete val.id_warehouse_rack_row;
                delete val.id_warehouse_rack_row_name;
            });

            this.bugTableSelected();
        },
        async bugTableSelected() {
            let first = this.$refs.selectableTable.isRowSelected(0);
            if (first) {
                await this.$refs.selectableTable.unselectRow(0);
                await this.$refs.selectableTable.selectRow(0);
            } else {
                await this.$refs.selectableTable.selectRow(0);
                await this.$refs.selectableTable.unselectRow(0);
            }
        },
        onRowSelected(items) {
            this.selected = items
        },
        async searchDO() {
            try {
                let arr = [];
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/delivery_order/wh/find/no_do", {
                        no_do: this.formInput.no_do_in,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.id_pr = resp.data.data.id_purchase_request
                    resp.data.data.get_products.forEach((value) => {
                        arr.push(value.product)
                    });
                }
                this.do_data = arr
                this.products = arr
            } catch (error) {
                // if (typeof error.response.data != undefined) {
                //     return await this.$toastr.e(
                //         error.response.data.message,
                //         "Request DO"
                //     );
                // }
                // return await this.$toastr.e(error, "Request DO");
                this.do_data = [{}]
            } finally {
                this.$store.dispatch('setLoading', false);
                this.findPR();
            }
        },
        async onSearchWarehouse(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/warehouse", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.warehouse = resp.data.data;
                }
            } catch (error) {
                if (typeof error.response.data != undefined) {
                    return await this.$toastr.e(
                        error.response.data.message,
                        "Request Warehouse"
                    );
                }
                return await this.$toastr.e(error, "Request Warehouse");
            }
        },
        async findPR() {
            try {

                if (this.id_pr == null) {
                    return false;
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/purchase_request/wh/find", {
                        id: this.id_pr,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.formInput.no_pr = resp.data.data.no_pr
                }

                // return this.$router.push('purchase-request');
            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Purchase Request"
                    );
                    return this.$router.push('receive-item');
                }
                this.$toastr.e(error, "Request Purchase Request");
                return this.$router.push('receive-item');
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async onSearchPurchaseOrder(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/receive_item/wh/purchase_order", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.purchase_orders = resp.data.data
                    // this.purchase_orders = await resp.data.data.filter(function (val) {
                    //     return val.products.length > 0;
                    // });
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Purchase Order", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedPurchaseOrder(val) {

            this.defaultExpDate = '';

            if (val == null) {
                this.formInput.no_so = '';
                this.formInput.no_po = '';
                this.formInput.no_invoice_in = '';
                this.formInput.no_pr = '';
                this.products = [];
                return;
            }

            if (val.products.length == 0) {
                return this.$toastr.w(
                    'Purchase Request has been used',
                    "Error"
                );
            }

            this.products = val.products
            this.formInput.no_po = val.no_po;
            this.formInput.no_pr = val.no_pr;
            this.formInput.no_so = val.no_so;
            this.formInput.no_invoice_in = val.no_invoice_in;
            // this.products = val.products.map(value => {
            //     value.exp_date = "";
            //     value.id_warehouse_rack = "";
            //     value.id_warehouse_rack_name = "";
            //     value.id_warehouse_rack_row = "";
            //     value.id_warehouse_rack_row_name = "";
            // })

            this.products.map(function (e) {
                e.exp_date = "";
                e.id_warehouse_rack = "";
                e.id_warehouse_rack_name = "";
                e.id_warehouse_rack_row = "";
                e.id_warehouse_rack_row_name = "";
            })
        },
        async handleRack(value, iteration) {
            if (value == null) {
                delete iteration.id_warehouse_rack;
                delete iteration.id_warehouse_rack_name;
                delete iteration.id_warehouse_rack_row;
                delete iteration.id_warehouse_rack_row_name;
                delete iteration.racks_row;
                return;
            }

            iteration.racks_row = value.rack_row;
            iteration.id_warehouse_rack = value.id;
            iteration.id_warehouse_rack_name = value.name;
            delete iteration.id_warehouse_rack_row_name;
        },
        async handleRackRow(value, iteration) {
            if (value == null) {
                delete iteration.id_warehouse_rack_row;
                delete iteration.id_warehouse_rack_row_name;
                return;
            }
            iteration.racks_row = value.rack_row;
            iteration.id_warehouse_rack_row = value.id;
            iteration.id_warehouse_rack_row_name = value.name;
        },
        async setSelectedWarehouse(value) {

            if (value == null) {
                return;
            }

            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/receive_item/wh/get_warehouse", {
                        id: value,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.products.map((val) => {
                        delete val.id_warehouse_rack;
                        delete val.id_warehouse_rack_name;
                        delete val.id_warehouse_rack_row;
                        delete val.id_warehouse_rack_row_name;
                        delete val.racks_row;
                    });
                    this.racks = resp.data.data.rack;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async handleSubmit() {

            // if (!this.formInput.no_po) {
            //     this.$swal.fire("Error", 'The No Purchase Order field can not be empty', "error");
            //     return;
            // }

            // if (!this.formInput.no_do_in) {
            //     this.$swal.fire("Error", 'The No Delivery Order field can not be empty', "error");
            //     return;
            // }
            // if (!this.formInput.id_warehouse) {
            //     this.$swal.fire("Error", 'The Warehouse field can not be empty', "error");
            //     return;
            // }
            // if (!this.formInput.incoming_date) {
            //     this.$swal.fire("Error", 'The Incoming date field can not be empty', "error");
            //     return;
            // }

            this.formInput.products = this.selected;
            this.formInput.no_po_in = this.formInput.no_po;

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        // if (this.formInput.products.length > 100) {
                        //     this.formInput.products.length.forEach(function (value, index) {

                        //     })
                        // }
                        let resp = await axios.post(
                            "dental-erp-service/product_detail/wh/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.selected = [];
                            this.warehouse = [];
                            this.purchase_order = [];
                            this.purchase_orders = [];
                            this.products = [];
                            this.racks = [];
                            this.racks_row = [];
                            this.defaultRackSelect = '';
                            this.defaultExpDate = '';
                            this.formInput.no_po_in = '';
                            this.no_po = '';

                            this.formInput = {
                                no_po: '',
                                no_pr: '',
                                no_so: '',
                                no_do_in: '',
                                id_warehouse: '',
                                id_warehouse_rack: '',
                                id_warehouse_rack_row: '',
                                incoming_date: '',
                                products: []
                            };
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
