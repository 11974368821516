<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
        </div>
        <router-link class="btn btn-outline-info" to="demo-request" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Demo Request
        </router-link>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Delivery Order</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Delivery Order</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_do" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Customer</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.customer_name" />
                            </div>
                            <!-- <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                    <label class="text text-info">Type</label>
                                    <v-select :options="types" v-model="formInput.type"></v-select>
                                </div>
                                <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                    <label class="text text-info">Courier</label>
                                    <v-select :options="courier" label="name" :reduce="courier => courier.name_code" v-model="formInput.courier"></v-select>
                                </div>
                                <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                    <label class="text text-info">No Resi</label>
                                    <input type="text" class="form-control" required v-model="formInput.no_resi" />
                                </div>
                                <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                    <label class="text text-info">Shipping Charge</label>
                                    <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.shipping_charge" />
                                </div> -->
                            <!-- <div class="form-group col-md-6 col-lg-3 col-xl-2" v-if="formInput.back_date == 1">
                                <label class="text text-info">Date Transaction</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_transaction" :max="new Date()" @input="requestNumberDO">
                                </b-form-datepicker>
                            </div> -->
                        </div>
                        <hr class="pb-4 mt-4">
                        <h4>List Item (Select The Item)</h4>
                        <div class="form-row">
                            <div class="pt-4 table-responsive" v-if="shelter.length > 0">
                                <table class="table dark-table-bordered text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>QTY</th>
                                            <th>Stock Available</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in shelter" :key="index">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }} <span v-if="value.is_bonus == 1" class="badge badge-xs badge-info">Bonus</span></td>
                                            <td>{{ value.qty }}</td>
                                            <td>{{ value.stock_available }}</td>
                                            <td>
                                                <b-button-group>
                                                    <b-button title="FIFO" @click="getDetailItem('fifo', value)" v-b-modal.modal-select-item class="mr-1" variant="outline-secondary" size="sm"> FIFO </b-button>
                                                    <b-button title="FEFO" @click="getDetailItem('fefo', value)" v-b-modal.modal-select-item class="mr-1" variant="outline-info" size="sm"> FEFO </b-button>
                                                </b-button-group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr class="pb-4 mt-5">
                        <div class="form-row" v-if="formInput.products.length > 0">
                            <h4>Item Selected</h4>
                            <div class="pt-4 table-responsive">
                                <table class="table success-table-bordered text-center">
                                    <thead class="thead-success">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>Serial Number</th>
                                            <th>Incoming Date</th>
                                            <th>Exp Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in formInput.products" :key="index">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }}</td>
                                            <td>{{ value.serial_number }}</td>
                                            <td>{{ value.incoming_date }}</td>
                                            <td>{{ value.exp_date }}</td>
                                            <td>
                                                <b-button title="Remove" @click="removeSelectedItem(index)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-trash"></i>
                                                </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <p class="card-text text-dark d-inline">Total Item: {{ formInput.products.length || 0 }}</p>
                    </div>
                    <div class="mt-4">
                        <button @click="handleSubmit" class="btn btn-outline-success mb-1 mr-1" :disabled="this.formInput.products == 0"><i class="fa fa-save mr-1"></i> Create Delivery Order </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-select-item" button-size="sm" ref="my-modal-select-item" title="Select The Item" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <div class="table-responsive">
            <table class="table dark-table-bordered text-center">
                <thead class="thead-dark">
                    <tr>
                        <th>Part Number</th>
                        <th>Product Name</th>
                        <th>Rack</th>
                        <th>Row</th>
                        <th>Serial Number</th>
                        <th>Incoming Date</th>
                        <th>Exp Date</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, i) in stocks" :key="i">
                        <td>{{ val.part_number }}</td>
                        <td>{{ val.product_name }}</td>
                        <td>{{ val.rack_name }}</td>
                        <td>{{ val.row_name }}</td>
                        <td>{{ val.serial_number }}</td>
                        <td>{{ val.incoming_date }}</td>
                        <td>{{ val.exp_date }}</td>
                        <td>
                            <b-button v-if="!val.select" title="Select" @click="selectItem(val)" class="mr-1" variant="outline-success" pill size="sm"><i class="fa fa-check-circle"></i></b-button>
                            <b-button v-else title="Remove" @click="removeItem(val)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-minus"></i></b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "TodoDeliveryOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Delivery Order | %s',
    },
    data() {
        return {
            fields: ['part_number', 'product_name', 'serial_number', 'incoming_date', 'exp_date', 'selected'],
            selected: [],
            products: [],
            stocks: [],
            id_product_demo: [],
            qty: 0,
            formInput: {
                no_do: '',
                id_demo_request: '',
                id_customer: '',
                id_sales: '',
                back_date: 0,
                products: [],
                customer_name: '',
            },
            shelter: [],
        };
    },
    async created() {
        await this.onLoad();
        await this.requestNumberDO();
    },
    methods: {
        handleDateChange(input) {
            if (input == 0) {
                this.requestNumberDO();
            }
        },
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('demo-request');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/demo_request/sales/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.customer_name = resp.data.data.customer.organization;
                    this.formInput.id_customer = resp.data.data.customer.id;
                    this.formInput.id_demo_request = resp.data.data.id;
                    this.formInput.id_sales = resp.data.data.id_sales;

                    // each every products
                    await resp.data.data.get_products.forEach(function (value, index) {
                        if (value.approved == 1 && value.id_product_detail === null) {
                            if (index == 0) {
                                arr.push({
                                    id_product: value.id_product,
                                    part_number: value.product.part_number,
                                    product_name: value.product.name,
                                    stock_available: value.stock_available,
                                    qty: 1,
                                    id_product_demo: [value.id]
                                });
                            } else {
                                let found = false;

                                for (let i = 0; i < arr.length; i++) {
                                    if (arr[i].part_number == value.product.part_number) {
                                        arr[i].qty += 1;
                                        arr[i].id_product_demo.push(value.id);
                                        found = true;
                                        break;
                                    }
                                }

                                if (found == false) {
                                    arr.push({
                                        id_product: value.id_product,
                                        part_number: value.product.part_number,
                                        product_name: value.product.name,
                                        stock_available: value.stock_available,
                                        qty: 1,
                                        id_product_demo: [value.id]
                                    });
                                }
                            }
                        }
                    });
                    this.shelter = arr;
                    return this.sales_order = resp.data.data;
                }

                return this.$router.push('demo-request');
            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Sales Order"
                    );
                    return this.$router.push('demo-request');
                }
                this.$toastr.e(error, "Request Demo");
                return this.$router.push('demo-request');
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        // async getTypes() {
        //     try {
        //         const resp = await axios.get(
        //             "dental-erp-service/delivery_order/wh/order_type", {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.types = resp.data.data;
        //         }

        //     } catch (error) {
        //         if (typeof error.response.data != undefined) {
        //             this.$toastr.e(
        //                 error.response.data.message,
        //                 "Request Types"
        //             );
        //             return this.$router.push('demo-request');
        //         }
        //         this.$toastr.e(error, "Request Types");
        //     }
        // },
        // async getCourier() {
        //     try {
        //         const resp = await axios.get(
        //             "dental-erp-service/tracking-package/courier", {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.courier = resp.data.data;
        //         }

        //     } catch (error) {
        //         if (typeof error.response.data != undefined) {
        //             this.$toastr.e(
        //                 error.response.data.message,
        //                 "Request Courier"
        //             );
        //         }
        //         this.$toastr.e(error, "Request Courier");
        //     }
        // },
        async requestNumberDO() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'DO');
                params.append('back_date', this.formInput.back_date);
                params.append('date', this.formInput.back_date == 0 ? '' : this.formInput.date_transaction);

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_do = resp.data.data.number;
                }

            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Number DO"
                    );
                    return this.$router.push('demo-request');
                }
                this.$toastr.e(error, "Request Number DO");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getDetailItem(action, value) {
            try {
                this.id_product_demo = Object.assign([], value.id_product_demo);
                this.qty = value.qty;
                await this.filterIdProductSO();

                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/delivery_order/wh/stock", {
                        id_product: value.id_product,
                        sort_by: action,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.stocks = [];

                    await resp.data.data.forEach(val => {

                        let found = false;
                        for (let i = 0; i < this.formInput.products.length; i++) {
                            let element = this.formInput.products[i];
                            if (element.id == val.id) {
                                found = true;
                                break;
                            }
                        }

                        if (found == false) {
                            this.stocks.push({
                                select: false,
                                ...val
                            });
                        }
                    });
                }
            } catch (error) {
                console.log(error);
                this.$swal.fire("Error", error.response.data.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async selectItem(item) {
            if (this.id_product_demo == 0) {
                this.$swal.fire("Error", "The Item selected is more than requested QTY", "error");
                return false;
            }

            for (let index = 0; index < this.stocks.length; index++) {
                let element = this.stocks[index];
                if (element.id === item.id) {
                    element.id_product_demo = this.id_product_demo[0];
                    element.select = true;
                    break;
                }
            }

            // await this.stocks.map((value) => {
            //     if (value.id == item.id) {
            //         value.id_product_demo = this.id_product_demo[0]
            //         value.select = true;
            //     }
            // });
            await this.id_product_demo.shift();
        },
        async removeItem(item) {
            await this.stocks.map((value) => {
                if (value.id == item.id) {
                    this.id_product_demo.push(value.id_product_demo);
                    value.select = false;
                    delete value.id_product_demo;
                }
            });
        },
        async filterIdProductSO() {
            for (let i = 0; i < this.id_product_demo.length; i++) {
                for (let index = 0; index < this.formInput.products.length; index++) {
                    if (this.formInput.products[index].id_product_demo == this.id_product_demo[i]) {
                        this.id_product_demo.splice(i, 1);
                    }
                }
            }
        },
        async handleOk() {
            await this.stocks.forEach(element => {
                if (element.select && element.id_product_demo != undefined) {
                    this.formInput.products.push(element);
                }
            });
        },
        async removeSelectedItem(index) {
            this.formInput.products.splice(index, 1);
        },
        async handleSubmit() {

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        let products = await this.formInput.products.map(value => {
                            return {
                                id_product_detail: value.id,
                                id_product_demo: value.id_product_demo,
                                id_product: value.id_product
                            }
                        });

                        let resp = await axios.post(
                            "dental-erp-service/demo_request/wh/todo_do", {
                                no_do: this.formInput.no_do,
                                id_demo_request: this.formInput.id_demo_request,
                                id_customer: this.formInput.id_customer,
                                id_sales: this.formInput.id_sales,
                                back_date: 0,
                                products: products
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('demo-request');
                        }
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire("Error", error.response.data.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.table-responsive {
    max-height:500px;
}
</style>
