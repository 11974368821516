<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <!-- <b-button variant="outline-info" v-b-modal.modal-add>
                    <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Register Product
                </b-button> -->
        <router-link class="btn btn-outline-info" to="purchase-request">
            <span class="fa fa-arrow-left mr-2"></span> Back to Purchase Request
        </router-link>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Create Delivery Order</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <b-form-checkbox id="checkbox-1" v-model="formInput.back_date" name="checkbox-1" value="1" unchecked-value="0" @change="handleDateChange">
                            Back Date
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="card-body">
                    <form ref="formAdd" @submit.prevent="handleSubmit">
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Delivery Order</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_do" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Type</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.type">
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Courier</label>
                                <v-select :options="courier" label="name" :reduce="courier => courier.name_code" v-model="formInput.courier"></v-select>
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No Resi</label>
                                <input type="text" class="form-control" required v-model="formInput.no_resi" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Shipping Charge</label>
                                <currency-input type="text" :valueAsInteger="true" :precision="0" :currency="null" locale="id" :allowNegative="false" :distractionFree="false" class="form-control" required v-model="formInput.shipping_charge" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2" v-if="formInput.back_date == 1">
                                <label class="text text-info">Date Transaction</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="formInput.date_transaction" :max="new Date()" @input="requestNumberDO">
                                </b-form-datepicker>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Purchase Request</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_pr" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text">Warehouse</label>
                                <div class="input-group">
                                    <v-select style="width:100%" label="name" :filterable="false" :options="warehousesList" @input="selectedWarehouse" @search="onSearchWarehouse">
                                        <template slot="no-options"> type to search warehouse... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <!-- <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">No. Purchase Order</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.no_po" />
                            </div>
                            <div class="form-group col-md-6 col-lg-3 col-xl-2">
                                <label class="text text-info">Customer</label>
                                <input type="text" class="form-control" readonly required v-model="formInput.customer_name" />
                            </div> -->
                        </div>
                        <hr class="pb-4 mt-4">
                        <h4>List Item (Select The Item)</h4>
                        <div class="form-row">
                            <div class="pt-4 table-responsive" v-if="shelter.length > 0">
                                <table class="table dark-table-bordered text-center">
                                    <thead class="thead-dark">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>QTY</th>
                                            <th>Safety Stock</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in shelter" :key="index">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }} <span v-if="value.is_bonus == 1" class="badge badge-xs badge-info">Bonus</span></td>
                                            <td>{{ value.qty }}</td>
                                            <td>{{ value.safety_stock }}</td>
                                            <td>
                                                <b-button title="FIFO" @click="getDetailItem('fifo', value)" v-b-modal.modal-select-item class="mr-1" variant="outline-secondary" squared size="sm"> FIFO </b-button>
                                                <b-button title="FEFO" @click="getDetailItem('fefo', value)" v-b-modal.modal-select-item class="mr-1" variant="outline-info" squared size="sm"> FEFO </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr class="pb-4 mt-5">
                        <div class="form-row" v-if="formInput.products.length > 0">
                            <h4>Item Selected</h4>
                            <div class="pt-4 table-responsive">
                                <table class="table success-table-bordered text-center">
                                    <thead class="thead-success">
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Product Name</th>
                                            <th>Serial Number</th>
                                            <th>Incoming Date</th>
                                            <th>Exp Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(value, index) in formInput.products" :key="index">
                                            <td>{{ value.part_number }}</td>
                                            <td>{{ value.product_name }}</td>
                                            <td>{{ value.serial_number }}</td>
                                            <td>{{ value.incoming_date }}</td>
                                            <td>{{ value.exp_date }}</td>
                                            <td>
                                                <b-button title="Remove" @click="removeSelectedItem(index)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-trash"></i>
                                                </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-footer d-sm-flex justify-content-between align-items-center">
                    <div class="card-footer-link mb-4 mb-sm-0">
                        <p class="card-text text-dark d-inline">Total Item: {{ formInput.products.length || 0 }}</p>
                    </div>
                    <div class="mt-4">
                        <button @click="handleSubmit" class="btn btn-outline-success mb-1 mr-1" :disabled="this.formInput.products == 0"><i class="fa fa-save mr-1"></i> Create Delivery Order </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-select-item" button-size="sm" ref="my-modal-select-item" title="Select The Item" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <div class="table-responsive">
            <table class="table dark-table-bordered text-center">
                <thead class="thead-dark">
                    <tr>
                        <th>Part Number</th>
                        <th>Product Name</th>
                        <th>Rack</th>
                        <th>Row</th>
                        <th>Serial Number</th>
                        <th>Incoming Date</th>
                        <th>Exp Date</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(val, i) in stocks" :key="i">
                        <td>{{ val.part_number }}</td>
                        <td>{{ val.product_name }}</td>
                        <td>{{ val.rack_name }}</td>
                        <td>{{ val.row_name }}</td>
                        <td>{{ val.serial_number }}</td>
                        <td>{{ val.incoming_date }}</td>
                        <td>{{ val.exp_date }}</td>
                        <td>
                            <b-button v-if="!val.select" title="Select" @click="selectItem(val)" class="mr-1" variant="outline-success" pill size="sm"><i class="fa fa-check-circle"></i></b-button>
                            <b-button v-else title="Remove" @click="removeItem(val)" class="mr-1" variant="outline-danger" pill size="sm"><i class="fa fa-minus"></i></b-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "TodoDeliveryOrderPurchaseRequest",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Create Delivery Order | %s',
    },
    data() {
        return {
            fields: ['part_number', 'product_name', 'serial_number', 'incoming_date', 'exp_date', 'selected'],
            selected: [],
            products: [],
            stocks: [],
            id_product_so: [],
            warehousesList: [],
            qty: 0,
            courier: [],
            sales_order: [],
            // types: [],
            formInput: {
                no_pr: '',
                no_do: '',
                id_sales_order: '',
                id_purchase_request: this.$route.query.id,
                back_date: 0,
                date_transaction: '',
                products: [],
                id_customer: '',
                customer_name: '',
                type: 'transfer',
                courier: '',
                no_resi: '',
                shipping_charge: 0,
            },
            shelter: [],
        };
    },
    async created() {
        await this.onLoad();
        await this.requestNumberDO();
        // await this.getTypes();
        await this.getCourier();
    },
    methods: {
        handleDateChange(input) {
            if (input == 0) {
                this.requestNumberDO();
            }
        },
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('purchase-request');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/purchase_request/wh/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    let arr = [];
                    this.formInput.no_pr = resp.data.data.no_pr;
                    this.products = resp.data.data.get_products;

                    resp.data.data.get_products;

                    await resp.data.data.get_products.forEach(function (value, index) {
                        console.log(value)
                        // if (value.approved == 1) {
                        if (index == 0) {
                            arr.push({
                                id_product: value.id_product,
                                part_number: value.product.part_number,
                                product_name: value.product.name,
                                // stock_available: value.stock_available,
                                safety_stock: value.product.safety_stock,
                                qty: 1,
                                id_product_so: [value.id],
                                is_bonus: value.is_bonus
                            });
                        } else {
                            let found = false;

                            for (let i = 0; i < arr.length; i++) {
                                if (arr[i].part_number == value.product.part_number && arr[i].is_bonus == value.is_bonus) {
                                    arr[i].qty += 1;
                                    arr[i].id_product_so.push(value.id);
                                    found = true;
                                    break;
                                }
                            }

                            if (found == false) {
                                arr.push({
                                    id_product: value.id_product,
                                    part_number: value.part_number,
                                    product_name: value.product_name,
                                    // stock_available: value.stock_available,
                                    safety_stock: value.safety_stock,
                                    qty: 1,
                                    id_product_so: [value.id],
                                    is_bonus: value.is_bonus
                                });
                            }
                        }
                        // }
                    });
                    return this.shelter = arr;
                }

                // return this.$router.push('purchase-request');
            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Purchase Request"
                    );
                    return this.$router.push('purchase-request');
                }
                this.$toastr.e(error, "Request Purchase Request");
                return this.$router.push('purchase-request');
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async onSearchWarehouse(query) {
            try {
                const resp = await axios.post(
                    "dental-erp-service/warehouse", {
                        limit: 10,
                        page: 1,
                        ascending: false,
                        search: query
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.warehousesList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        // async getTypes() {
        //     try {
        //         const resp = await axios.get(
        //             "dental-erp-service/delivery_order/wh/order_type", {
        //                 headers: {
        //                     Authorization: localStorage.getItem("token") || "",
        //                 },
        //             }
        //         );

        //         if (resp.data.error === false) {
        //             return this.types = resp.data.data;
        //         }

        //     } catch (error) {
        //         if (typeof error.response.data != undefined) {
        //             this.$toastr.e(
        //                 error.response.data.message,
        //                 "Request Types"
        //             );
        //             return this.$router.push('purchase-request');
        //         }
        //         this.$toastr.e(error, "Request Types");
        //     }
        // },
        async getCourier() {
            try {
                const resp = await axios.get(
                    "dental-erp-service/tracking-package/courier", {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.courier = resp.data.data;
                }

            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Courier"
                    );
                }
                this.$toastr.e(error, "Request Courier");
            }
        },
        async requestNumberDO() {
            try {
                const params = new URLSearchParams();
                params.append('type', 'DO');
                params.append('back_date', this.formInput.back_date);
                params.append('date', this.formInput.back_date == 0 ? '' : this.formInput.date_transaction);

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.get(
                    "dental-erp-service/generate/number", {
                        params: params,
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.formInput.no_do = resp.data.data.number;
                }

            } catch (error) {
                if (typeof error.response.data != undefined) {
                    this.$toastr.e(
                        error.response.data.message,
                        "Request Number DO"
                    );
                    return this.$router.push('purchase-request');
                }
                this.$toastr.e(error, "Request Number DO");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getDetailItem(action, value) {
            try {
                this.id_product_so = Object.assign([], value.id_product_so);
                this.qty = value.qty;
                await this.filterIdProductSO();

                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/delivery_order/wh/stock", {
                        id_product: value.id_product,
                        sort_by: action,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.stocks = [];

                    await resp.data.data.forEach(val => {

                        let found = false;
                        for (let i = 0; i < this.formInput.products.length; i++) {
                            let element = this.formInput.products[i];
                            if (element.id == val.id) {
                                found = true;
                                break;
                            }
                        }

                        if (found == false) {
                            this.stocks.push({
                                select: false,
                                ...val
                            });
                        }
                    });
                }
            } catch (error) {
                console.log(error);
                if (error.response.data.message == "Trying to get property 'id_warehouse' of non-object") {
                    this.$swal.fire("Error", "You're not assigned to warehouse, please contact administrator", "error");
                }
                else {
                    this.$swal.fire("Error", error.response.data.message, "error");
                }
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        async selectItem(item) {
            if (this.id_product_so == 0) {
                this.$swal.fire("Error", "The Item selected is more than requested QTY", "error");
                return false;
            }

            for (let index = 0; index < this.stocks.length; index++) {
                let element = this.stocks[index];
                if (element.id === item.id) {
                    element.id_product_so = this.id_product_so[0];
                    element.select = true;
                    break;
                }
            }

            await this.stocks.map((value) => {
                if (value.id == item.id) {
                    value.id_product_so = this.id_product_so[0]
                    value.select = true;
                }
            });
            await this.id_product_so.shift();
        },
        async removeItem(item) {
            await this.stocks.map((value) => {
                if (value.id == item.id) {
                    this.id_product_so.push(value.id_product_so);
                    value.select = false;
                    delete value.id_product_so;
                }
            });
        },
        async filterIdProductSO() {
            for (let i = 0; i < this.id_product_so.length; i++) {
                for (let index = 0; index < this.formInput.products.length; index++) {
                    if (this.formInput.products[index].id_product_so == this.id_product_so[i]) {
                        this.id_product_so.splice(i, 1);
                    }
                }
            }
        },
        async handleOk() {
            await this.stocks.forEach(element => {
                if (element.select && element.id_product_so != undefined) {
                    this.formInput.products.push(element);
                }
            });
        },
        async selectedWarehouse(selected) {
            this.formInput.id_warehouse = selected.id
        },
        async removeSelectedItem(index) {
            this.formInput.products.splice(index, 1);
        },
        async handleSubmit() {

            if (this.formInput.back_date == 1 && this.formInput.date_transaction == "") {
                this.$swal.fire("Error", 'The Date Transaction field can not be empty', "error");
                return;
            }

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch("setLoading", true);
                        await this.formInput.products.map((value) => {
                            value.id_product_detail = value.id
                        });

                        let resp = await axios.post(
                            "dental-erp-service/delivery_order/wh/store",
                            this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.$router.push('detail-delivery-order?id=' + resp.data.id);
                        }
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire("Error", error.response.data.message, "error");
                    } finally {
                        await this.$store.dispatch("setLoading", false);
                    }
                }
            });
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.table-responsive {
    max-height: 500px;
}
</style>
