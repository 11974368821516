<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <button class="btn btn-outline-info" @click="handleBackRoute">
            <span class="fa fa-arrow-left mr-2"></span> Back to Product List
        </button>
    </div>
    <div class="row">
        <div class="col-xl-2 col-xxl-12 col-lg-12 col-md-12 mt-3">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card bg-primary">
                        <div class="card-body">
                            <div class="row mx-0">
                                <!-- <div class="media align-items-center col-md-4 col-lg-3 col-xl-2 px-0  mb-md-0">
                                    <img class="mr-3 img-fluid rounded-circle" width="65" src="/assets/images/delivery.png" alt="DexignZone">
                                    <div class="media-body">
                                        <h4 class="my-0 text-black"> {{ sales_order.customer.organization }}</h4>
                                        <small>{{ sales_order.customer.phone_number }}</small>
                                    </div>
                                </div> -->
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Product Name</small>
                                    <p class="text-detail-css text-white">{{productInfo.name}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Part Number</small>
                                    <p class="text-detail-css text-white">{{productInfo.part_number}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Principle</small>
                                    <p class="text-detail-css text-white">{{productInfo.principle.name}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Category</small>
                                    <p class="text-detail-css text-white">{{productInfo.category.name}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Brand</small>
                                    <p class="text-detail-css text-white">{{productInfo.brand.name}}</p>
                                </div>
                                <div class="col-md-4 col-lg-3 col-xl-2 mb-md-0">
                                    <small class="text-title-css text-light">Piece Type</small>
                                    <p class="text-detail-css text-white">{{productInfo.piece}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 mt-5">
                    <div class="card">
                        <div class="card-header bg-success">
                            <div>
                                <h4 class="card-title text-white">
                                    <b-icon-file-earmark-richtext-fill></b-icon-file-earmark-richtext-fill> Product Log
                                </h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row mx-0">
                                <div>
                                    <b-tabs content-class="mt-5">
                                        <b-tab title="Product Log" active>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-header d-block d-sm-flex">
                                                            <div class="card-action card-tabs mt-3 mt-sm-0">
                                                                <div class="input-group">
                                                                    <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{ enabled: true, dropdownAllowAll: false, nextLabel: 'Next', prevLabel: 'Previous', setCurrentPage: paramQuery.page}" :rows="rows" :columns="columns" compactMode>
                                                                <template slot="table-row" slot-scope="props">
                                                                    <div v-if="props.column.field == 'action'">
                                                                        <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-purchase-order?id='+ props.row.id"><i class="fa fa-search"></i> Detail </b-button>
                                                                    </div>
                                                                    <span v-else>
                                                                        {{ props.formattedRow[props.column.field] }}
                                                                    </span>
                                                                </template>
                                                            </vue-good-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Sales Log">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-header d-block d-sm-flex">
                                                            <div class="card-action card-tabs mt-3 mt-sm-0">
                                                                <div class="input-group">
                                                                    <input type="text" v-on:keyup="onSearchChangeSales" v-model="paramQuerySales.search" class="form-control w-50" placeholder="Search...">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsSales" :pagination-options="{ enabled: true, dropdownAllowAll: false, nextLabel: 'Next', prevLabel: 'Previous', setCurrentPage: paramQuerySales.page}" :rows="rowsSales" :columns="columnsSales" compactMode>
                                                                <template slot="table-row" slot-scope="props">
                                                                    <div v-if="props.column.field == 'action'">
                                                                        <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-purchase-order?id='+ props.row.id"><i class="fa fa-search"></i> Detail </b-button>
                                                                    </div>
                                                                    <div v-else-if="props.column.field == 'price'">
                                                                        {{props.row.price.toLocaleString('id-ID')}}
                                                                    </div>
                                                                    <div v-else-if="props.column.field == 'total'">
                                                                        {{props.row.total.toLocaleString('id-ID')}}
                                                                    </div>
                                                                    <div v-else-if="props.column.field == 'disc'">
                                                                        {{props.row.disc.toLocaleString('id-ID')}}
                                                                    </div>
                                                                    <div v-else-if="props.column.field == 'sales_force'">
                                                                        <div v-if="props.row.sales_force !== null">
                                                                            <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                                                            <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                                                        </div>
                                                                        <div v-else>
                                                                            -
                                                                        </div>
                                                                    </div>
                                                                    <span v-else>
                                                                        {{ props.formattedRow[props.column.field] }}
                                                                    </span>
                                                                </template>
                                                            </vue-good-table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="row">
                                                            <div class="col-2">
                                                                <div class="card-body">
                                                                    <h4 class="card-title text-warning">Grand Total :</h4>
                                                                    <p class="text-detail-css">IDR {{this.grandTotalSales.total_price.toLocaleString('id-ID')}}</p>
                                                                    <!-- <h4 class="card-title mt-3 text-warning">Qty Total :</h4>
                                                            <p class="text-detail-css">{{this.grandTotalSales.total_qty}}</p> -->
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="card-body">
                                                                    <!-- <h4 class="card-title text-warning">Grand Total :</h4>
                                                                    <p class="text-detail-css">IDR {{this.grandTotalSales.total_price.toLocaleString('id-ID')}}</p> -->
                                                                    <h4 class="card-title text-warning">Qty Total :</h4>
                                                                    <p class="text-detail-css">{{this.grandTotalSales.total_qty}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-2">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <h4 class="card-title text-warning">Grand Total :</h4>
                                                            <p class="text-detail-css">IDR {{this.grandTotalSales.total_price.toLocaleString('id-ID')}}</p>
                                                            <h4 class="card-title mt-3 text-warning">Qty Total :</h4>
                                                            <p class="text-detail-css">{{this.grandTotalSales.total_qty}}</p>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </b-tab>
                                        <b-tab title="Purchase Log">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-header d-block d-sm-flex">
                                                            <div class="card-action card-tabs mt-3 mt-sm-0">
                                                                <div>Test </div>
                                                                <div class="input-group">
                                                                    <input type="text" v-on:keyup="onSearchChangePurchase" v-model="paramQueryPurchase.search" class="form-control w-50" placeholder="Search...">
                                                                </div>
                                                                <!-- <div class="input-group">
                                                                    <input type="text" v-model="defaultRate" class="form-control w-50" placeholder="Rate">
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecordsPurchase" :pagination-options="{ enabled: true, dropdownAllowAll: false, nextLabel: 'Next', prevLabel: 'Previous', setCurrentPage: paramQueryPurchase.page}" :rows="rowsPurchase" :columns="columnsPurchase" compactMode>
                                                                <template slot="table-row" slot-scope="props">
                                                                    <div v-if="props.column.field == 'action'">
                                                                        <b-button title="Detail" variant="outline-info" size="sm" :to="'detail-purchase-order?id='+ props.row.id"><i class="fa fa-search"></i> Detail </b-button>
                                                                    </div>
                                                                    <span v-else>
                                                                        {{ props.formattedRow[props.column.field] }}
                                                                    </span>
                                                                </template>
                                                            </vue-good-table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="row">
                                                            <div class="col-2">
                                                                <div class="card-body">
                                                                    <h4 class="card-title text-warning">Grand Total :</h4>
                                                                    <p class="text-detail-css">IDR {{this.grandTotalPurchase.total_price.toLocaleString('id-ID')}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="card-body">
                                                                    <h4 class="card-title text-warning">Qty Total :</h4>
                                                                    <p class="text-detail-css">{{this.grandTotalPurchase.total_qty}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header d-block">
                            <h4 class="card-title">Product List</h4>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <!-- <div class="col-xl-3 col-xxl-2 col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-6 col-md-6">
                    <div class="card h-auto">
                        <div class="card bg-secondary sticky mb-0">
                            <div class="card-header border-0 pb-0">
                                <h5 class="card-title text-white mt-2">Note Order</h5>
                            </div>
                            <div class="card-body pt-3">
                                <p class="fs-14 op7 text-white">{{ sales_order.note }} </p>
                            </div>
                            <div class="card-footer border-0 py-4 bg-primary">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="media align-items-center">
                                            <img class="mr-3 img-fluid rounded-circle" width="50" style="object-fix: cover;" :src="sales_order.create_by_data.profile" alt="DexignZone">
                                            <div class="media-body">
                                                <span class="text-white">Create By:</span>
                                                <h5 class="my-0 text-white"> {{ sales_order.create_by_data.first_name }} <br> {{ sales_order.create_by_data.title }} </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="media align-items-center">
                                            <img class="mr-3 img-fluid rounded-circle" width="50" style="object-fix: cover;" :src="sales_order.sales_by_data.profile" alt="DexignZone">
                                            <div class="media-body">
                                                <span class="text-white">Sales User:</span>
                                                <h5 class="my-0 text-white"> {{ sales_order.sales_by_data.first_name }} <br> {{ sales_order.sales_by_data.title }} </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "ProductRecord",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Product Record | %s',
    },
    data() {
        return {
            columns: [{
                    label: "Transaction Number",
                    field: "transaction",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer",
                    hidden: false,
                },
                {
                    label: "On Process",
                    field: "qty_proceed",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Requested",
                    field: "qty_request",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Transaction Type",
                    field: "type",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
            ],

            columnsSales: [{
                    label: "SO Number",
                    field: "no_so",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer",
                    hidden: false,
                },
                {
                    label: "Order Type",
                    field: "order_type",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "SO Created Date",
                    field: "date_so",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Sales",
                    field: "sales_force",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Quantity",
                    field: "qty",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Price",
                    field: "price",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Discount",
                    field: "disc",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Total",
                    field: "total",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
            ],

            columnsPurchase: [{
                    label: "PO Number",
                    field: "no_po",
                    hidden: false,
                },
                {
                    label: "Principle",
                    field: "principle",
                    hidden: false,
                },
                {
                    label: "PO Created Date",
                    field: "date_po",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Quantity",
                    field: "qty",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Currency",
                    field: "currency",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Price",
                    field: "price",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Discount",
                    field: "disc",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Price After Discount",
                    field: "after_disc",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Total",
                    field: "total",
                    tdClass: "text-center",
                    hidden: false,
                    sortable: false,
                },
            ],

            totalApproved: 0,
            totalRecords: 0,
            totalRecordsSales: 0,
            totalRecordsPurchase: 0,
            grandTotalSales: 0,
            grandTotalPurchase: 0,
            defaultRate: 1,
            rows: [],
            rowsSales: [],
            rowsPurchase: [],
            productInfo: [],
            paramQuery: {
                id_product: this.$route.query.id,
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date,
                page: 1,
                limit: 10,
                ascending: false,
            },
            paramQuerySales: {
                id_product: this.$route.query.id,
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date,
                page: 1,
                limit: 10,
                ascending: false,
            },
            paramQueryPurchase: {
                id_product: this.$route.query.id,
                start_date: this.$route.query.start_date,
                end_date: this.$route.query.end_date,
                page: 1,
                limit: 10,
                ascending: false,
            },
            paramProductInfo: {
                id: this.$route.query.id,
            },
        };
    },
    async created() {
        await this.getData();
        await this.getSales();
        await this.getPurchase();
        await this.getProductInfo();
    },
    methods: {
        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/record/product/find", this.paramQuery, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    return this.rows = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Product Record", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Record ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getSales() {
            try {

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/record/product/sales", this.paramQuerySales, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    });

                if (resp.data.error === false) {
                    this.totalRecordsSales = resp.data.pagination.total;
                    this.grandTotalSales = resp.data.grand_total;
                    return this.rowsSales = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Product Record Sales", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Record Sales", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getPurchase() {
            try {

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/record/product/purchase", this.paramQueryPurchase, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    });

                if (resp.data.error === false) {
                    this.totalRecordsPurchase = resp.data.pagination.total;
                    this.grandTotalPurchase = resp.data.grand_total;
                    return this.rowsPurchase = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Product Record Purchase", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Record Purchase", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getProductInfo() {
            try {

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/product/find", this.paramProductInfo, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    });

                if (resp.data.error === false) {
                    return this.productInfo = resp.data.data;
                }

            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Product Record", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product Record ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        handleBackRoute() {
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('dashboard')
        },
        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
            this.paramQuerySales = Object.assign({}, this.paramQuerySales, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
            this.getSales();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        onSearchChangeSales() {
            this.updateParams({
                page: 1
            });
            this.getSales();
        },

        onSearchChangePurchase() {
            this.updateParams({
                page: 1
            });
            this.getPurchase();
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}

.text-detail-css {
    margin: 0;
    color: #484848;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.text-title-css {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
}
</style>
