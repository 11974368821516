<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button-group>
            <b-button variant="outline-info" to="management-inventory">
                <b-icon icon="arrow-left" aria-hidden="true" animation="fade"></b-icon> Back to Management Inventories List
            </b-button>
            <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" @click="clearForm" animation="fade"></b-icon> Add Employee
            </b-button>
        </b-button-group>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Management Inventory - Employee of {{warehouseName}}</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <!-- <div class="input-group-prepend"> -->
                            <!-- <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Columns</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select> -->
                            <!-- <v-select label="label" placeholder="Select one" class="input-group-text" v-model="paramQuery.column" :reduce="col => col.field" :options="columnRender" /> -->
                            <!-- </div> -->
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'full_name'">
                                <div v-if="props.row.first_name !== null">
                                    <img :src="props.row.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.first_name }}</span>
                                    <span class="w-space-no">{{ props.row.last_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Delete" variant="outline-danger" size="sm" @click="handleDelete(props.row)"><i class="fa fa-trash"></i></b-button>
                                </b-button-group>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-add" size="xl" title="Form Rack" @ok="handleSubmit" button-size="sm">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12 col-lg-12">
                    <label class="text text-info">Search Employee Name</label>
                    <v-select label="first_name" :filterable="false" :options="listEmployee" @input="selectedEmployee" @search="onSearchEmployee">
                        <template slot="no-options"> type to search employee... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                {{ option.first_name}} {{ option.last_name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.first_name}} {{ option.last_name }}
                            </div>
                        </template>
                    </v-select>
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "AddEmployee",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Add Employee | %s',
    },
    data() {
        return {
            formInput: {
                id_user: '',
                id_warehouse: this.$route.query.id,
            },
            columns: [{
                    label: "Name",
                    field: "full_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Title",
                    field: "title",
                    hidden: false,
                },
                {
                    label: "Phone Number",
                    field: "phone_number",
                    // thClass: "text-center",
                    // tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Email",
                    field: "email",
                    // thClass: "text-center",
                    // tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            // listProduct: [],
            warehouseName: "",
            listEmployee: [],
            rows: [],
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_warehouse: this.$route.query.id
            },
        };
    },
    // computed: {
    //     columnRender: function () {
    //         return this.columns.filter(i =>
    //             i.field != 'action' &&
    //             i.field != 'warehouse_name' &&
    //             i.field != 'products'
    //         )
    //     },
    // },
    async mounted() {
        if (this.$route.query.id) {
            await this.getData();
        }
    },
    methods: {

        async selectedEmployee(selected) {
            this.formInput.id_user = selected.id
        },

        async onSearchEmployee(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "user-service/management/user/list", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.listEmployee = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async getData() {
            this.rows = []
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/warehouse_staff", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.warehouseName = resp.data.data[0].warehouse_name;
                    for (let i = 0; i < resp.data.data.length; i++) {
                        resp.data.data[i].staff_data["id_user"] = resp.data.data[i].id
                        this.rows.push(resp.data.data[i].staff_data)
                    }
                    // this.rows = resp.data.data;
                    // this.rows.push(resp.data.data[0].staff_data)
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async handleDelete(row) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        let resp = await axios.delete(
                            "dental-erp-service/warehouse_staff/delete", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: row.id_user
                                }
                            });

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.clearForm()
                            this.getData()
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        async handleSubmit() {
            try {
                this.formInput.logo = [];
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/warehouse_staff/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-import');
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.clearForm()
                    this.getData()
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
        clearForm() {
            this.formInput = {
                id_user: '',
                id_warehouse: this.$route.query.id,
            }
        },
        // async changeColumn(column) {
        //     for (var i in this.columns) {
        //         if (this.columns[i].label == column.label) {
        //             this.columns[i].hidden =
        //                 "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
        //             break;
        //         }
        //     }
        // },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
