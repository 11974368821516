<template>
<div>
    <b-modal id="modal-import" ref="my-modal-import" title="Form Import Stock" size="xl" centered no-close-on-esc no-close-on-backdrop @ok="handleOk">
        <form @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label class="text text-info">Select Product</label>
                    <v-select label="name" :filterable="false" :options="productsList" @input="selectedProduct" @search="onSearchProduct">
                        <template slot="no-options"> type to search product... </template>
                        <template slot="option" slot-scope="option">
                            <div class="d-center">
                                [{{ option.part_number}}] {{ option.name }}
                            </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                                {{ option.name }}
                            </div>
                        </template>
                    </v-select>
                    <!-- <input type="text" class="form-control" placeholder="Enter the part number" required v-model="formInput.id" /> -->
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Qty</label>
                    <input type="number" class="form-control" placeholder="Enter Quantity..." required v-model="shelter.qty" />
                </div>
                <div class="form-group col-md-6">
                    <label class="text text-info">Expired Date</label>
                    <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="shelter.expired_date">
                    </b-form-datepicker>
                </div>
                <div class="form-group col-md-2">
                    <b-button-group>
                        <b-button :disabled="shelter.id == null || shelter.qty == null || shelter.expired_date == null" title="Import Excel" variant="outline-info" size="sm" @click="addProducttoList"> Add Product to List </b-button>
                    </b-button-group>
                </div>
            </div>
            <div class="form-row mt-5">
                <div class="form-group col-md-12">
                    <label class="text text-info">Import via Excel</label>
                    <b-form-file v-model="files" accept=".xls, .xlsx" v-on:change="onFileChange($event)"></b-form-file>
                </div>
                <div class="form-group col-md-12">
                    <b-button-group>
                        <b-button :disabled="formInputExcel.name_file == ''" title="Import Excel" variant="outline-warning" size="sm" @click="importExcel"> Import to List </b-button>
                    </b-button-group>
                </div>
            </div>
            <div class="pt-4 table-responsive" v-if="formInput.products.length > 0">
                <table class="table dark-table-bordered text-center">
                    <thead class="thead-dark">
                        <tr>
                            <th>Product Name</th>
                            <th>Qty</th>
                            <th>Expired Date</th>
                            <th>Remove</th>
                            <th>
                                <b-button-group>
                                    <b-button title="Edit Table" variant="warning" size="xs" @click="editableState"> Edit List </b-button>
                                </b-button-group>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="tableEditable">
                        <tr v-for="(val, index) in formInput.products" :key="index">
                            <td>
                                <input type="text" v-model="val.name">
                            </td>
                            <td>
                                <input type="number" v-model="val.qty">
                            </td>
                            <td>
                                <input type="text" v-model="val.expired_date">
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(val, index) in formInput.products" :key="index">
                            <td>{{ val.name }}</td>
                            <td>{{ val.qty }}</td>
                            <td>{{ val.expired_date }}</td>
                            <td>
                                <b-button variant="outline-danger" pill size="sm" @click="removeItem(index)"><i class="fa fa-trash"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css'

export default {
    name: "ImportStock",
    props: {
        propsRow: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tableEditable: false,
            files: [],
            formInput: {
                products: []
            },
            formInputExcel: {
                content_file: '',
                name_file: ''
            },
            productsList: [],
            shelter: {},
            data: []
        };
    },
    watch: {
        propsRow: {
            immediate: true,
            handler() {
                this.formInput.id_warehouse = this.propsRow.id
                this.formInputExcel.id_warehouse = this.propsRow.id
            },
        },
    },
    methods: {
         async removeItem(index) {
            this.formInput.products.splice(index, 1);
        },
        addProducttoList() {
            this.formInput.products.push({
                ...this.shelter
            })
            this.shelter = {}
        },

        editableState() {
            this.tableEditable = !this.tableEditable
        },

        async importExcel() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/receive_item/wh/v2/import",
                    this.formInputExcel, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.data = resp.data.data
                    for (var i = 0; i < this.data.length; i++) {
                        // var sliced = this.data.slice(1);
                        delete this.data[i].part_number
                    }
                    this.formInput.products = this.formInput.products.concat(this.data)
                    this.data = []
                    this.files = []
                    this.formInputExcel = {
                        content_file: '',
                        name_file: '',
                        id_warehouse: this.propsRow.id
                    }
                    // this.formInput.products.push({
                    //     ...this.data
                    // })
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },

        async selectedProduct(selected) {
            this.shelter.id = selected.id
            this.shelter.name = selected.name
        },

        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.productsList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onFileChange(event) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0])
            reader.onload = () => {
                this.formInputExcel.name_file = event.target.files[0].name
                this.formInputExcel.content_file = reader.result.split(',')[1]
            };
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },
        async handleSubmit() {
            try {
                this.formInput.logo = [];
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.post(
                    "dental-erp-service/receive_item/wh/store",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    await this.$bvModal.hide('modal-import');
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.formInput = {
                        products: []
                    }
                    this.shelter = {}
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                await this.$store.dispatch("setLoading", false);
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
