<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block">
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <b-button-group>
            <b-button variant="outline-info" to="management-inventory">
                <b-icon icon="arrow-left" aria-hidden="true" animation="fade"></b-icon> Back to Management Inventories List
            </b-button>
            <b-button variant="outline-info" :to="'rack-row?id='+ $route.query.id"> To Row</b-button>
            <b-button variant="outline-info" v-b-modal.modal-add>
                <b-icon icon="plus-circle-fill" aria-hidden="true" @click="clearForm" animation="fade"></b-icon> Add Rack
            </b-button>
        </b-button-group>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Management Inventory - Racks</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Columns</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                                <!-- <v-select label="label" placeholder="Select one" class="input-group-text" v-model="paramQuery.column" :reduce="col => col.field" :options="columnRender" /> -->
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search...">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'products'">
                                <b-button title="Detail product" variant="outline-secondary" @click="id_rack = props.row.id" size="sm" v-b-modal.modal-list-products v-if="props.row.current_capacity > 0">{{ props.row.current_capacity }}</b-button>
                                <span class="text-weight-bold" v-else>{{ props.row.current_capacity }}</span>
                            </div>
                            <div v-else-if="props.column.field == 'action'">
                                <b-button-group>
                                    <b-button title="Edit" variant="outline-info" size="sm" @click="formInput = Object.assign({}, props.row)" v-b-modal.modal-add><i class="fa fa-pencil"></i></b-button>
                                    <b-button title="Delete" variant="outline-danger" size="sm" @click="handleDelete(props.row)"><i class="fa fa-trash"></i></b-button>
                                </b-button-group>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-list-products" size="xl" title="Product List" button-size="sm">
        <rack-table :id-rack="id_rack" />
    </b-modal>
    <b-modal id="modal-add" size="xl" title="Form Rack" @ok="handleSubmit" button-size="sm">
        <form ref="formAdd" @submit.prevent="handleSubmit">
            <div class="form-row">
                <div class="form-group col-md-6 col-lg-8">
                    <label class="text text-info">Rack Name</label>
                    <input type="text" class="form-control" placeholder="Enter the rack name" v-model="formInput.name" />
                </div>
                <div class="form-group col-md-6 col-lg-4">
                    <label class="text text-info">Capacity</label>
                    <input type="number" class="form-control" placeholder="Enter the capacity" min="0" v-model.number="formInput.capacity" />
                </div>
            </div>
        </form>
    </b-modal>
</div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
import RackTable from "./RackTable.vue"

export default {
    name: "Rack",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Rack | %s',
    },
    components: {
        RackTable
    },
    data() {
        return {
            formInput: {
                id: '',
                id_warehouse: this.$route.query.id,
                name: '',
                capacity: '',
            },
            id_rack: '',
            columns: [{
                    label: "Warehouse Name",
                    field: "warehouse_name",
                    sortable: false,
                    hidden: false,
                },
                {
                    label: "Rack Name",
                    field: "name",
                    hidden: false,
                },
                {
                    label: "Capacity",
                    field: "capacity",
                    thClass: "text-center",
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Total product",
                    field: "products",
                    thClass: "text-center",
                    tdClass: "text-center",
                    hidden: false,
                },
                {
                    label: "Action",
                    field: "action",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
            listProduct: [],
            rows: [],
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_warehouse: this.$route.query.id
            },
        };
    },
    computed: {
        columnRender: function () {
            return this.columns.filter(i =>
                i.field != 'action' &&
                i.field != 'warehouse_name' &&
                i.field != 'products'
            )
        },
    },
    async mounted() {
        if (this.$route.query.id) {
            await this.getData();
        }
    },
    methods: {

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/management_inventory/rack", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async handleDelete(row) {
            this.$swal.fire({
                title: 'Are you sure delete this data?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        let resp = await axios.delete(
                            "dental-erp-service/management_inventory/rack/destroy", {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                                data: {
                                    id: row.id
                                }
                            });

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.clearForm()
                            this.getData()
                        }
                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Error", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Error", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            });
        },
        async handleSubmit() {
            if (this.formInput.name.length == 0) {
                return this.$swal.fire("Error", "Rack name is required", "error");
            }

            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post(
                    this.formInput.id == "" ? "dental-erp-service/management_inventory/rack/store" : "dental-erp-service/management_inventory/rack/update",
                    this.formInput, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    });

                if (resp.data.error === false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.clearForm()
                    this.getData()
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        clearForm() {
            this.formInput = {
                id: '',
                name: '',
                capacity: '',
                id_warehouse: this.$route.query.id,
            }
        },
        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
