<template>
<div class="container-fluid">
    <!-- <MenuApp /> -->
    <div class="row mt-5">
        <div class="col-lg-12 col-xl-12 col-md-12">
            <div class="row">
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Procurement.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{totalStock}}</span>
                                    </h3>
                                    <p class="mb-0">Stock</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Asset-Management.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{deliveredStock}}</span>
                                    </h3>
                                    <p class="mb-0">Delivered</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 pl-1 pr-1">
                    <div class="widget-stat card card-information">
                        <div class="card-body p-4">
                            <div class="media ai-icon">
                                <span class="mr-3 text-primary">
                                    <img src="/assets/icon/Task.png" style="width:70px;height:70px">
                                </span>
                                <div class="media-body">
                                    <h3 class="mb-0 text-black">
                                        <span class="counter ml-0">{{availableStock}}</span>
                                    </h3>
                                    <p class="mb-0">Received Item</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-xl-8">
                <div class="card">
                    <div class="card-header bg-warning">
                        <div class="mr-3">
                            <h4 class="card-title text-white">You have {{ExpiredList.grand_total.qty_total}} Product That Will Expired Soon</h4>
                        </div>
                        <!-- <div class="card-action card-tabs mt-3 mt-sm-0">
                            <div class="input-group">
                                <input type="text" v-on:keyup="onSearchChangeExpired" v-model="paramQueryExpired.search" class="form-control w-50" placeholder="Search...">
                            </div>
                        </div> -->
                    </div>
                    <div class="card-body tab-content pt-3">
                        <div v-for="(expired, index) in ExpiredList.data" :key="index" class="media items-list-1">
                            <a><img class="img-fluid rounded mr-3" width="85" src="/assets/logo/erp-dental.png" alt="DexignZone"></a>
                            <div class="media-body col-sm-4 col-6 col-xxl-5 px-0">
                                <h5 class="mt-0 mb-3"><a class="text-black">[{{expired.part_number}}] {{expired.name}}</a></h5>
                                <strong class="text-secondary mr-2">{{expired.qty}} Available in Stock</strong>
                            </div>
                            <div class="media-footer ml-auto col-3 px-0 d-flex align-self-center align-items-center">
                                <div class="mr-3">
                                </div>
                                <div>
                                    <h3 class="mb-2 font-w600 text-black">{{expired.expired_date}}</h3>
                                </div>
                            </div>
                        </div>
                        <footer style="text-align:right;">
                            <div class="btn-group" role="group">
                                <button v-if="paramQueryExpired.page == 1" disabled type="button" class="btn btn-info">
                                    <b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill>
                                </button>
                                <button v-else type="button" class="btn btn-info" @click="ExpiredPreviousButtonClick">
                                    <b-icon-arrow-left-circle-fill></b-icon-arrow-left-circle-fill>
                                </button>
                                <button v-if="paramQueryExpired.page >= ExpiredList.pagination.total_pages" disabled type="button" class="btn btn-info">
                                    <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
                                </button>
                                <button v-else type="button" class="btn btn-info" @click="ExpiredNextButtonClick">
                                    <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
                                </button>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>

            <div style="height: 810px;" class="col-xl-4">
                <div class="card">
                    <div class="card-header bg-success border-0 pb-0 d-sm-flex flex-wrap d-block">
                        <div class="mb-3">
                            <h4 class="card-title text-white mb-1">Best Selling Products</h4>
                        </div>
                        <div class="card-action card-tabs mb-3">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#all1" role="tab">All</a></li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#monthly1" role="tab">Monthly</a></li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#weekly1" role="tab">Weekly</a></li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#annually1" role="tab">Annually</a></li>
                            </ul>
                        </div>
                    </div>
                    <div style="height:100%;overflow:auto;text-align:center;" class="card-body tab-content mt-3">
                        <div class="tab-pane fade" id="monthly1">
                            <div class="row height750 dz-scroll loadmore-content" id="favourite-items2Content">
                                <div v-for="(bestproduct, index) in BestProductMonthlyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                    <div style="justify-content: center;" class="media mb-4">
                                        <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                        <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                    </div>
                                    <div class="info">
                                        <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="weekly1">
                            <div class="row height750 dz-scroll loadmore-content" id="favourite-items3Content">
                                <div v-for="(bestproduct, index) in BestProductWeeklyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                    <div style="justify-content: center;" class="media mb-4">
                                        <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                        <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                    </div>
                                    <div class="info">
                                        <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="all1">
                            <div class="row height750 dz-scroll loadmore-content" id="favourite-itemsContent">
                                <div v-for="(bestproduct, index) in BestProductAllList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                    <div style="justify-content: center;" class="media mb-4">
                                        <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                        <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                    </div>
                                    <div class="info">
                                        <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="bg-white pt-3 text-center">
                                <a href="javascript:void(0);" class="btn-link dz-load-more" rel="ajax/favourite-items.html" id="favourite-items3">View more <i class="fa fa-angle-down ml-2 scale-2"></i></a>
                            </div> -->
                        </div>
                        <div class="tab-pane fade" id="annually1">
                            <div class="row height750 dz-scroll loadmore-content" id="favourite-items4Content">
                                <div v-for="(bestproduct, index) in BestProductAnuallyList" :key="index" class="col-md-4 col-xl-4 col-xxl-6 col-sm-6 mb-5">
                                    <div style="justify-content: center;" class="media mb-4">
                                        <a v-if="bestproduct.file_url == null"><img src="/assets/logo/erp-dental.png" style="width:100%;" class="rounded" alt="" /></a>
                                        <a v-else><img :src="bestproduct.file_url" style="width:100px;height:100px" class="rounded" alt="" /></a>
                                    </div>
                                    <div class="info">
                                        <h5 class="text-black mb-3"><a class="text-black">{{bestproduct.name}}</a></h5>
                                        <a href="javascript:void(0);" class="btn btn-primary light btn-sm btn-rounded px-4"><i class="fa fa-shopping-cart mr-2 scale5 "></i> <strong>{{bestproduct.sale}}</strong> Sold</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header bg-info">
                        <!-- <div class="mb-3">
                                <h4 class="card-title text-white mb-1">Product Demo Status</h4>
                            </div> -->
                        <h4 class="card-title text-white mb-1">Product Demo Status</h4>
                    </div>
                    <div class="card-body tab-content pt-3 mt-3">
                        <div class="card-body">
                            <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeDemo" @on-per-page-change="onPerPageChangeDemo" :totalRows="totalRecordsDemo" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryDemo.page,
                perPage: 5,
              }" :rows="rowsDemo" :columns="columnsDemo" compactMode>
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'sales_force'">
                                        <div v-if="props.row.sales_force !== null">
                                            <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                            <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </div>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card">
                    <div class="card-header bg-info">
                        <!-- <div class="mb-3">
                            <h4 class="card-title text-white mb-1">Incoming Product</h4>
                        </div> -->
                        <!-- <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"> -->
                        <h4 class="card-title text-white mb-1">Incoming Product</h4>
                        <!-- </button> -->
                    </div>
                    <!-- <div class="card-body tab-content pt-3 mt-3"> -->
                    <div class="card-body">
                        <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChangeReceive" @on-per-page-change="onPerPageChangeReceive" :totalRows="totalRecordsReceive" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQueryReceive.page,
                perPage: 5,
              }" :rows="rowsReceive" :columns="columnsReceive" compactMode>
                            <template slot="table-row" slot-scope="props">
                                <!-- <div v-if="props.column.field == 'sales_force'">
                                        <div v-if="props.row.sales_force !== null">
                                            <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                            <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </div>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span> -->
                                <span>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import MenuApp from '../../components/MenuApp';
import axios from "axios";

export default {
    name: "Dashboard",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Dashboard Warehouse | %s',
    },
    // components: {
    //     MenuApp
    // },
    data() {
        return {
            totalStock: 0,
            deliveredStock: 0,
            availableStock: 0,
            totalPurchaseRequest: 0,
            totalSalesOrder: 0,
            totalDeliveryOrder: 0,
            ExpiredList: [],
            BestProductAnuallyList: [],
            BestProductWeeklyList: [],
            BestProductMonthlyList: [],
            BestProductAllList: [],
            rowsDemo: [],
            rowsReceive: [],
            totalRecordsDemo: 0,
            totalRecordsReceive: 0,
            paramQueryExpired: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
            paramQueryDemo: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
            paramQueryReceive: {
                page: 1,
                limit: 5,
                search: "",
                column: "",
                ascending: false
            },
            columnsDemo: [{
                    label: "Part Number",
                    field: "part_number",
                    tdClass: "text-left text-nowrap",
                    hidden: false,
                },
                {
                    label: "Name",
                    field: "name",
                    thClass: 'text-center',
                    tdClass: "text-left text-nowrap",
                    hidden: false,
                },
                {
                    label: "Customer",
                    field: "customer",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
                {
                    label: "Sales Force",
                    field: "sales_force",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
                {
                    label: "Start Date",
                    field: "start_date",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
                {
                    label: "End Date",
                    field: "end_date",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-left text-nowrap",
                    sortable: false,
                },
                {
                    label: "Quantity",
                    field: "qty",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
                {
                    label: "Delivered",
                    field: "delivered",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
                {
                    label: "Returned",
                    field: "returned",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],

            columnsReceive: [{
                    label: "Name",
                    field: "name",
                    tdClass: "text-nowrap",
                    hidden: false,
                },
                {
                    label: "PO Number",
                    field: "no_po",
                    hidden: false,
                    sortable: false,
                },
                {
                    label: "Principle",
                    field: "principle_name",
                    hidden: false,
                    tdClass: "text-nowrap",
                    sortable: false,
                },
                {
                    label: "Incoming Date",
                    field: "incoming_date",
                    hidden: false,
                    tdClass: "text-nowrap",
                    sortable: false,
                },
                {
                    label: "Warehouse",
                    field: "warehouse_name",
                    hidden: false,
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
                {
                    label: "Quantity",
                    field: "qty",
                    hidden: false,
                    thClass: "text-center",
                    tdClass: "text-center text-nowrap",
                    sortable: false,
                },
            ],
        };
    },
    methods: {

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get('dental-erp-service/counting/stock', {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalStock = resp.data.data.total_stock_product;
                    this.deliveredStock = resp.data.data.total_stock_delivered;
                    this.availableStock = resp.data.data.total_stock_available;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getExpiredList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/expired_list", this.paramQueryExpired, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.ExpiredList = resp.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getProductDemo() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/demo_list", this.paramQueryDemo, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsDemo = resp.data.pagination.total;
                    this.rowsDemo = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getReceive() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/receive_list", this.paramQueryDemo, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecordsReceive = resp.data.pagination.total;
                    this.rowsReceive = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductAnuallyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'annually'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductAnuallyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductMonthlyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'monthly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductMonthlyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductWeeklyList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'weekly'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductWeeklyList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                // this.$store.dispatch('setLoading', false);
            }
        },

        async getBestProductAllList() {
            try {
                // await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/dashboard/best_product", {
                    filter: 'all'
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.BestProductAllList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        ExpiredNextButtonClick() {
            this.paramQueryExpired.page = this.paramQueryExpired.page + 1
            this.getExpiredList();
        },

        ExpiredPreviousButtonClick() {
            this.paramQueryExpired.page = this.paramQueryExpired.page - 1
            this.getExpiredList();
        },

        updateParamsExpired(newProps) {
            this.paramQueryExpired = Object.assign({}, this.paramQueryExpired, newProps);
        },

        updateParamsDemo(newProps) {
            this.paramQueryDemo = Object.assign({}, this.paramQueryDemo, newProps);
        },

        updateParamsReceive(newProps) {
            this.paramQueryReceive = Object.assign({}, this.paramQueryReceive, newProps);
        },

        onPageChangeDemo(params) {
            this.updateParamsDemo({
                page: params.currentPage
            });
            this.getProductDemo();
        },

        onPageChangeReceive(params) {
            this.updateParamsReceive({
                page: params.currentPage
            });
            this.getReceive();
        },

        onPerPageChangeDemo(params) {
            this.updateParamsDemo({
                limit: params.currentPerPage,
                page: 1
            });
            this.getProductDemo();
        },

        onPerPageChangeReceive(params) {
            this.updateParamsReceive({
                limit: params.currentPerPage,
                page: 1
            });
            this.getReceive();
        },

        onSearchChangeExpired() {
            this.updateParamsExpired({
                page: 1
            });
            this.getExpiredList();
        },

        onSearchChangeReceive() {
            this.updateParamsReceive({
                page: 1
            });
            this.getReceive();
        },

        // clearInputDemo() {
        //     this.paramQueryConsignment.search = ""
        //     this.getConsignment();
        // },

    },
    async mounted() {
        await this.$store.dispatch("setApp");
        await this.getData();
        await this.getExpiredList();
        await this.getProductDemo();
        await this.getReceive();
        await this.getBestProductAnuallyList();
        await this.getBestProductWeeklyList();
        await this.getBestProductMonthlyList();
        await this.getBestProductAllList();
    },
};
</script>

<style>
.card-information {
    box-shadow: 3px 3px 3px rgb(205, 205, 212);
    background-color: #F7F7F7;
}
</style>
