<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <div class="mr-auto d-none d-lg-block"></div>
        <b-button variant="outline-info" to="management-inventory">
            <span class="fa fa-arrow-left mr-2"></span> Back to Management Inventory List
        </b-button>
    </div>
    <div class="row">
        <div class="col-xl-8 col-xxl-9 col-lg-8 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">List Product</h4>
                        </div>
                        <div class="card-body">
                            <!-- <b-table outlined hover ref="selectableTable" selectable select-mode="multi" :items="products" :fields="fields" @row-selected="onRowSelected">
                                <template #cell(selected)="{ rowSelected }">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                        <span class="sr-only">Selected</span>
                                    </template>
                                    <template v-else>
                                        <span aria-hidden="true">&nbsp;</span>
                                        <span class="sr-only">Not selected</span>
                                    </template>
                                </template>
                            </b-table> -->
                            <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" 
                            :select-options="{
                                enabled: true,
                                selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                                selectionInfoClass: 'custom-class',
                                selectionText: 'rows selected',
                                clearSelectionText: 'clear',
                                disableSelectInfo: false, // disable the select info panel on top
                                selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                            }"
                            @on-selected-rows-change="selectionChanged"
                            :pagination-options="{
                                    enabled: true,
                                    dropdownAllowAll: false,
                                    setCurrentPage: paramQuery.page,
                                    nextLabel: 'Next',
                                    prevLabel: 'Previous',
                                    setCurrentPage: paramQuery.page,
                                }" :rows="rows" :columns="columns" compactMode>
                                <template slot="table-row" slot-scope="props">
                                    <div v-if="props.column.field == 'latitude'">
                                        {{ props.row.latitude }} &ndash; {{ props.row.longitude }}
                                    </div>
                                    
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-xxl-3 col-lg-4 col-md-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Move Item on Warehouse:
                                <span class="text-info">{{ data.name }}</span>
                            </h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>Rack</label>
                                            <v-select label="name" :filterable="false" v-model="option.rack" :options="racks" @search="onSearchRack" @input="setSelectedRack">
                                                <template slot="no-options"> type to search rack ... </template>
                                                <template slot="option" slot-scope="option">
                                                    <div class="d-center">
                                                        {{ option.name }}
                                                    </div>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">
                                                        {{ option.name }}
                                                    </div>
                                                </template>
                                            </v-select>
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label>Row</label>
                                            <v-select label="name" :filterable="false" v-model="option.row" :options="rows_rack" @search="onSearchRow" @input="setSelectedRow">
                                                <template slot="no-options"> type to search row ... </template>
                                                <template slot="option" slot-scope="option">
                                                    <div class="d-center">
                                                        {{ option.name }}
                                                    </div>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">
                                                        {{ option.name }}
                                                    </div>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card-footer d-sm-flex justify-content-between align-items-center">
                            <div class="card-footer-link mb-4 mb-sm-0">
                                <p class="card-text text-dark d-inline">Selected Item: {{ selected.length }}</p>
                            </div>
                            <button class="btn btn-info" :disabled="selected.length == 0" @click="handleMove"> Move Item </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "MoveItem",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Move Item | %s',
    },
    data() {
        return {
            columns: [{
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Product Name",
                    field: "name",
                    hidden: false,
                },
                {
                    label: "Exp Date",
                    field: "exp_date",
                    hidden: false,
                },
                {
                    label: "Serial Number",
                    field: "serial_number",
                    hidden: false,
                },
                {
                    label: "Rack",
                    field: "warehouse_rack.name",
                    hidden: false,
                },
                {
                    label: "Row",
                    field: "warehouse_rack_row.name",
                    hidden: false,
                },
            ],
            rows: [],
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                search: "",
                column: "",
                ascending: false,
                id_warehouse: this.$route.query.id
            },
            // fields: ['part_number', 'product_name', 'exp_date', 'serial_number', 'rack_name', 'row_name', 'selected'],
            selected: [],
            data: {},
            products: [],
            racks: [],
            rows_rack: [],
            option: {
                rack: '',
                row: ''
            },
            formInput: {
                id_warehouse_rack: '',
                id_warehouse_rack_row: '',
                products: []
            }
        }
    },
    async created() {
        this.onLoad();
        this.getData()
    },
    methods: {
        selectionChanged(select) {
            this.selected = select.selectedRows.map(v => {
                return {
                    id_product_detail: v.id
                }
            })
        },
        async onLoad() {
            try {
                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/management_inventory/find", {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.data = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
                return await this.$toastr.e(error, "Request Detail");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        onSearchChange() {
            this.updateParams({
                page: 1
            });
            this.getData();
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/management_inventory/product_list", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },

        async getProduct() {
            try {
                const resp = await axios.post(
                    "dental-erp-service/management_inventory/product_list", {
                        id: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    // this.products = resp.data.data.product_in_warehouse.sort(function (a, b) {
                    //     if (a.product_name < b.product_name) {
                    //         return -1;
                    //     }
                    //     if (a.product_name > b.product_name) {
                    //         return 1;
                    //     }
                    //     return 0;
                    // });
                    this.data = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Product", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Product ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
                return await this.$toastr.e(error, "Request Detail");
            }
        },
        async onSearchRack(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/management_inventory/rack", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "name",
                        ascending: false,
                        id_warehouse: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.racks = await resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Racks", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Racks", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedRack(val) {

            if (val == null) {
                this.formInput.id_warehouse_rack = '';
                return;
            }

            this.formInput.id_warehouse_rack = val.id;
        },
        async onSearchRow(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/management_inventory/rack_row", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "name",
                        ascending: false,
                        id_warehouse: this.$route.query.id
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.rows_rack = await resp.data.data.filter(v => v.id_warehouse_rack == this.formInput.id_warehouse_rack);
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Rows", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Rows", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async setSelectedRow(val) {

            if (val == null) {
                this.formInput.id_warehouse_rack_row = '';
                return;
            }

            this.formInput.id_warehouse_rack_row = val.id;
        },
        onRowSelected(items) {
            this.selected = items
        },
        async handleMove() {

            if (!this.formInput.id_warehouse_rack) {
                this.$swal.fire("Error", 'The Rack field can not be empty', "error");
                return;
            }

            this.formInput.products = this.selected

            this.$swal.fire({
                title: 'Are you sure save this data?',
                text: "You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, save it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await this.$store.dispatch('setLoading', true);
                        let resp = await axios.post(
                            "dental-erp-service/management_inventory/move_product", this.formInput, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.$swal.fire("Success", resp.data.message, "success");
                            this.getData()
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }

                }
            });
        },
    }
}
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
