<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start" v-if="shelter.length != 0">
        <div class="mr-auto d-lg-block">
            <h2 class="text-black font-w600 mb-0">Delivery Order</h2>
            <!-- <p class="mb-0">Here is your general customers list data</p> -->
        </div>
        <router-link class="btn btn-outline-info mr-1" to="delivery-order" exact>
            <span class="fa fa-arrow-left mr-2"></span> Back to Delivery Order List
        </router-link>
        <button class="btn btn-outline-info" @click="generatePDF"> <i class="fa fa-file-pdf-o"></i> Generate Delivery Order </button>
    </div>
    <div class="row" v-if="shelter.length != 0">
        <div class="col-lg-12">
            <div class="card mt-3">
                <div class="card-header font-weight-bold"> No Delivery Order: {{ shelter.no_do }} <span class="float-right">
                        <strong>{{ shelter.date_transaction | moment('dddd, DD MMMM YYYY') }}</strong>
                        <!-- <strong>Status:</strong> <span :class=" shelter.date_paid == null ? 'text-danger' : 'text-info' ">{{ shelter.date_paid == null ? 'Unpaid' : 'Paid at ' + shelter.date_paid }} </span>  -->
                    </span>
                </div>
                <div class="card-body">
                    <div class="row mb-5">
                        <div v-if="shelter.customer != null" class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h6>To:</h6>
                            <div> <strong> {{ shelter.customer.organization }} </strong> </div>
                            <div>{{ shelter.customer.email }}</div>
                            <div>{{ shelter.customer.phone_number }}</div>
                            <div>{{ shelter.customer.address }}</div>
                        </div>
                        <div v-else class="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <h6>To:</h6>
                            <div> <strong> {{ shelter.warehouse_destination.name }} </strong> </div>
                            <div>{{ shelter.warehouse_destination.address }}</div>
                        </div>
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right" v-if="shelter.so !== null">
                            <h6>Info: </h6>
                            <div>No Delivery Order: <strong>{{ shelter.no_do }}</strong></div>
                            <div>No Sales Order: <strong>{{ shelter.so.no_so }}</strong></div>
                            <div>No Purchase Order: <strong>{{ shelter.so.no_po }}</strong></div>
                            <div>Date: <strong>{{ shelter.date_transaction }} </strong> </div>
                            <div v-if="shelter.back_date != 0">Back Date: <strong>{{ shelter.back_date }}</strong> </div>
                            <div>Type: <strong>{{ shelter.type }}</strong> </div>
                        </div>
                        <div class="mt-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 text-right" v-else>
                            <h6>Info: </h6>
                            <div>No Delivery Order: <strong>{{ shelter.no_do }}</strong></div>
                            <div>Date: <strong>{{ shelter.date_transaction }} </strong> </div>
                            <div v-if="shelter.back_date != 0">Back Date: <strong>{{ shelter.back_date }}</strong> </div>
                            <div>Type: <strong>{{ shelter.type }}</strong> </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="center">No</th>
                                    <th>Part Number</th>
                                    <th>Item</th>
                                    <th>SN</th>
                                    <th class="text-center">Qty</th>
                                    <th class="text-center">Piece</th>
                                    <th class="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, index) in shelter.get_products" :key="index">
                                    <td class="center">{{ index+1 }}</td>
                                    <td>{{ value.product.part_number }}</td>
                                    <td>
                                        {{ value.product.product_name == undefined ? value.product_name : value.product.product_name }}
                                        <span v-if="value.is_bonus == 1" class="badge badge-info">Bonus</span>
                                    </td>
                                    <td>{{ value.product.serial_number }}</td>
                                    <td class="text-center"> 1 </td>
                                    <td class="text-center">{{ value.product.piece }}</td>
                                    <td class="text-center">
                                        <span class="btn btn-sm btn-success"> Outgoing </span>
                                    </td>
                                </tr>
                                <tr v-for="(pending, id) in shelter.pending_list" :key="'pending_' + id">
                                    <td class="center">{{ id + shelter.get_products.length + 1 }}</td>
                                    <td>{{ pending.part_number }}</td>
                                    <td>{{ pending.product_name }}</td>
                                    <td> - </td>
                                    <td class="text-center"> 1 </td>
                                    <td class="text-center">{{ pending.piece }}</td>
                                    <td class="text-center">
                                        <span class="btn btn-sm btn-warning"> Pending </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintDeliveryOrder :data-delivery-order="shelter" ref="pdfComponent" hidden />
</div>
</template>

<script>
import axios from "axios";
import PrintDeliveryOrder from "./PrintDeliveryOrder";

export default {
    name: "DetailDeliveryOrder",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Detail Delivery Order | %s',
    },
    components: {
        PrintDeliveryOrder
    },
    data() {
        return {
            shelter: {},
        };
    },
    async created() {
        this.onLoad()
    },
    methods: {
        async onLoad() {
            try {
                if (!this.$route.query.id) {
                    return this.$router.push('delivery-order');
                }

                await this.$store.dispatch('setLoading', true);
                const resp = await axios.post(
                    "dental-erp-service/delivery_order/wh/find", {
                        id: this.$route.query.id,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    return this.shelter = resp.data.data;
                }

                return this.$router.push('delivery-order');
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Detail Invoice Out", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Detail Invoice Out ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async generatePDF() {
            this.$refs.pdfComponent.generateReport();
        },
        async paidNow() {
            this.$swal.fire({
                title: 'Are you sure you have paid this invoice?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, of course!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        if (!this.$route.query.id) {
                            return this.$router.push('invoice-out');
                        }

                        await this.$store.dispatch('setLoading', true);
                        const resp = await axios.post(
                            "dental-erp-service/invoice_out/finance/update_payment", {
                                id: this.$route.query.id,
                            }, {
                                headers: {
                                    Authorization: localStorage.getItem("token") || "",
                                },
                            }
                        );

                        if (resp.data.error === false) {
                            this.onLoad();
                            this.$swal.fire("Success", resp.data.message, "success");
                        }

                    } catch (error) {
                        if (error.response != undefined) {
                            if (error.response.data != undefined) {
                                if (error.response.data.error_auth != undefined) {
                                    this.$swal.fire("Request Paid Invoice", error.response.data.message, "error");
                                    this.$store.dispatch("logOut").then(() => {
                                        this.$router.push("/login");
                                    });
                                    return;
                                }
                                return this.$swal.fire("Request Paid Invoice ", error.response.data.message, "error");
                            }
                        }

                        this.$sentry.captureException(error);
                        this.$swal.fire("Error", error.message, "error");
                    } finally {
                        this.$store.dispatch('setLoading', false);
                    }
                }
            })
        }
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
    border: 1px solid rgba(60, 60, 60, .26) !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
