<template>
<transition name="fade" mode="out-in" appear>
    <component :is="urlQuery"></component>
</transition>
</template>

<script>
import axios from "axios";
import Dashboard from '../views/WarehouseModule/Dashboard';
import ReceiveItem from '../views/WarehouseModule/ReceiveItem/ReceiveItem';
import PurchaseRequest from '../views/WarehouseModule/PurchaseRequest/PurchaseRequest';
import CreatePurchaseRequest from '../views/WarehouseModule/PurchaseRequest/CreatePurchaseRequest';
import SalesOrder from '../views/WarehouseModule/SalesOrder/SalesOrder';
import DetailSalesOrder from '../views/WarehouseModule/SalesOrder/DetailSalesOrder';
import TodoDeliveryOrder from '../views/WarehouseModule/SalesOrder/TodoDeliveryOrder';
import DeliveryOrder from '../views/WarehouseModule/DeliveryOrder/DeliveryOrder';
import ManagementInventory from '../views/WarehouseModule/ManagementInventory/ManagementInventory';
import Rack from '../views/WarehouseModule/ManagementInventory/Rack';
import RackRow from '../views/WarehouseModule/ManagementInventory/RackRow';
import ProductList from '../views/WarehouseModule/ProductList/ProductList';
import DetailDeliveryOrder from '../views/WarehouseModule/DeliveryOrder/DetailDeliveryOrder';
import DemoRequest from '../views/WarehouseModule/DemoRequest/DemoRequest';
import ProductDemo from '../views/WarehouseModule/ProductDemo/ProductDemo';
import DeliveryOrderDemoRequest from '../views/WarehouseModule/DemoRequest/TodoDeliveryOrder';
import MoveItem from '../views/WarehouseModule/ManagementInventory/MoveItem';
import ProductRecord from '../views/WarehouseModule/ProductList/ProductRecord';
import ExpiredRecord from '../views/WarehouseModule/ProductList/ExpiredRecord';
import StockReport from '../views/WarehouseModule/ManagementInventory/StockReport';
import ProductReport from '../views/WarehouseModule/ProductList/ProductReport';
import PurchaseReport from '../views/WarehouseModule/PurchaseRequest/PurchaseReport';
// import CreateDeliveryOrder from '../views/WarehouseModule/DeliveryOrder/CreateDeliveryOrder';
import AddEmployee from '../views/WarehouseModule/ManagementInventory/AddEmployee';
import TodoDeliveryOrderPurchaseRequest from '../views/WarehouseModule/PurchaseRequest/TodoDeliveryOrderPurchaseRequest';



export default {
    name: 'Warehouse',
    components: {
        Dashboard,
        ReceiveItem,
        PurchaseRequest,
        CreatePurchaseRequest,
        SalesOrder,
        DetailSalesOrder,
        TodoDeliveryOrder,
        DeliveryOrder,
        ManagementInventory,
        Rack,
        RackRow,
        ProductList,
        DetailDeliveryOrder,
        DemoRequest,
        ProductDemo,
        DeliveryOrderDemoRequest,
        MoveItem,
        ProductRecord,
        ExpiredRecord,
        StockReport,
        ProductReport,
        PurchaseReport,
        // CreateDeliveryOrder,
        AddEmployee,
        TodoDeliveryOrderPurchaseRequest
    },
    async created() {
        try {
            await this.$store.dispatch('setLoading', true);
            const resp = await axios.get("user-service/auth/menu?module=warehouse", {
                headers: {
                    Authorization: localStorage.getItem("token") || "",
                },
            });
            if (resp.data.error === false) {
                this.listApplication = resp.data.data;
                await this.$store.dispatch('setMenu', resp.data.data);
                return;
            }
        } catch (error) {
            if (error.response != undefined) {
                if (error.response.data != undefined) {
                    if (error.response.data.error_auth != undefined) {
                        this.$swal.fire("Get Application List", error.response.data.message, "error");
                        this.$store.dispatch("logOut").then(() => {
                            this.$router.push("/login");
                        });
                        return;
                    }
                    return this.$swal.fire("Get Application List ", error.response.data.message, "error");
                }
            }

            this.$sentry.captureException(error);
            this.$swal.fire("Error", error.message, "error");
        } finally {
            this.$store.dispatch("setApp");
            this.$store.dispatch('setLoading', false);
        }
    },
    computed: {
        urlQuery() {
            return this.$route.params.component || 'Dashboard';
        }
    }
}
</script>
