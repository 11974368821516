<template>
<div>
    <vue-html2pdf :show-layout="false" :float-layout="false" :enable-download="true" :preview-modal="false" :paginate-elements-by-height="2000" :filename="'DO ' + shelter.no_do === undefined ? '-' : shelter.no_do" :pdf-quality="2" :manual-pagination="true" pdf-format="a5" pdf-orientation="landscape" pdf-content-width="97%" ref="html2Pdf" v-if="shelter.length != 0">
        <section slot="pdf-content">
            <div class="container-fluid invoice-container" v-if="shelter.no_do !== undefined">
                <br>
                <br>
                <header>
                    <div class="row align-items-center">
                        <div class="col-sm-2">
                            <img width="80px" class="mr-3" src="/assets/logo/gratia.png">
                        </div>
                        <div class="col-sm-3 text-center text-sm-left mb-3 mb-sm-0" style="font-size: 10px;">
                            <div style="margin-left: -30px">
                            <h4 class="mb-0">SURAT JALAN</h4>
                            <p class="mb-0">{{ shelter.no_do }}</p>
                            </div>
                        </div>
                        <div class="col-sm-7 text-sm-right">
                            <p class="mb-0">Jakarta, {{ new Date() | moment('DD/MM/YYYY') }}</p>
                            <p class="mb-0">Kepada Yth</p>
                            <p v-if="shelter.customer != null" class="mb-0">{{ shelter.customer.organization }}</p>
                            <p v-else class="mb-0">{{ shelter.warehouse_destination.name }}</p>
                        </div>
                    </div>
                    <br>
                </header>
                <main>
                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td class="text-center border-top-0"><strong>No</strong></td>
                                        <td class="border-top-0"><strong>Part Number</strong></td>
                                        <td class="border-top-0"><strong>Item Description</strong></td>
                                        <td class="text-center border-top-0"><strong>Qty</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(value, index) in filterProduct" :key="index">
                                        <td class="text-center">{{ index + 1 }}</td>
                                        <td>{{ value.part_number }}</td>
                                        <td>
                                            {{ value.product_name }} {{ value.is_bonus == 1 ? '(Bonus)' : '' }}
                                        </td>
                                        <td class="text-center">{{ value.qty }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
                <br>
                <footer class="text-right">
                    <div class="row align-items-center">
                        <div class="col-sm-6 text-center text-sm-left mb-3 mb-sm-0">
                            <br><br><br><br><br>
                            ............................................<br>
                            Received By
                        </div>
                        <div class="col-sm-6 text-sm-right">
                            <br><br><br><br>
                            ............................................<br>
                            Shipped By
                        </div>
                    </div>

                </footer>
            </div>
        </section>
    </vue-html2pdf>
</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: 'PrintDeliveryOrder',
    props: {
        dataDeliveryOrder: {
            type: Object,
            required: true
        }
    },
    watch: {
        dataDeliveryOrder: {
            immediate: true,
            handler() {
                this.shelter = Object.assign({}, this.dataDeliveryOrder);
            }
        }
    },
    data() {
        return {
            shelter: {},
        };
    },
    computed: {
        filterProduct() {
            let arr = [];

            // each every products
            this.shelter.get_products.forEach(function (value, index) {

                if (index == 0) {
                    arr.push({
                        part_number: value.product.part_number,
                        product_name: value.product.product_name == undefined ? value.product_name : value.product.product_name,
                        qty: 1,
                        is_bonus: value.is_bonus
                    });
                } else {

                    let found = false;

                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].part_number == value.product.part_number && arr[i].is_bonus == value.is_bonus) {
                            found = true;
                            arr[i].qty += 1;
                            break;
                        }
                    }

                    if (found === false) {
                        arr.push({
                            part_number: value.product.part_number,
                            product_name: value.product.product_name == undefined ? value.product_name : value.product.product_name,
                            qty: 1,
                            is_bonus: value.is_bonus
                        });
                    }
                }
            });
            return arr.sort((a, b) => a.is_bonus - b.is_bonus);
        }
    },
    components: {
        VueHtml2pdf
    },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            var str = '';

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        }
    }
}
</script>

<style scoped>
.container-fluid {
    background: #e7e9ed;
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
}

form {
    padding: 0;
    margin: 0;
    display: inline;
}

img {
    vertical-align: inherit;
}

a,
a:focus {
    color: #0071cc;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:hover,
a:active {
    color: #0c2f55;
    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

a:focus,
a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
    outline: none;
}

p {
    line-height: 1.9;
}

blockquote {
    border-left: 5px solid #eee;
    padding: 10px 20px;
}

iframe {
    border: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000000;
    font-family: "Helvetica", sans-serif;
}

.table {
    color: #000000;
    line-height: 0px;
}

.table-hover tbody tr:hover {
    background-color: #f6f7f8;
}

/* Border Radius */
.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

/* Text Size */
.text-0 {
    font-size: 11px !important;
    font-size: 0.6875rem !important;
}

.text-1 {
    font-size: 12px !important;
    font-size: 0.75rem !important;
}

.text-2 {
    font-size: 14px !important;
    font-size: 0.875rem !important;
}

.text-3 {
    font-size: 16px !important;
    font-size: 1rem !important;
}

.text-4 {
    font-size: 15px !important;
    font-size: 1.125rem !important;
}

.text-5 {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
}

.text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

.text-7 {
    font-size: 28px !important;
    font-size: 1.75rem !important;
}

.text-8 {
    font-size: 32px !important;
    font-size: 2rem !important;
}

.text-9 {
    font-size: 36px !important;
    font-size: 2.25rem !important;
}

.text-10 {
    font-size: 40px !important;
    font-size: 2.50rem !important;
}

.text-11 {
    font-size: 44px !important;
    font-size: 2.75rem !important;
}

.text-12 {
    font-size: 48px !important;
    font-size: 3rem !important;
}

.text-13 {
    font-size: 52px !important;
    font-size: 3.25rem !important;
}

.text-14 {
    font-size: 56px !important;
    font-size: 3.50rem !important;
}

.text-15 {
    font-size: 60px !important;
    font-size: 3.75rem !important;
}

.text-16 {
    font-size: 64px !important;
    font-size: 4rem !important;
}

.text-17 {
    font-size: 72px !important;
    font-size: 4.5rem !important;
}

.text-18 {
    font-size: 80px !important;
    font-size: 5rem !important;
}

.text-19 {
    font-size: 84px !important;
    font-size: 5.25rem !important;
}

.text-20 {
    font-size: 92px !important;
    font-size: 5.75rem !important;
}

/* Line height */
.line-height-07 {
    line-height: 0.7 !important;
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-2 {
    line-height: 1.2 !important;
}

.line-height-3 {
    line-height: 1.4 !important;
}

.line-height-4 {
    line-height: 1.6 !important;
}

.line-height-5 {
    line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
    opacity: 0;
}

.opacity-1 {
    opacity: 0.1;
}

.opacity-2 {
    opacity: 0.2;
}

.opacity-3 {
    opacity: 0.3;
}

.opacity-4 {
    opacity: 0.4;
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-6 {
    opacity: 0.6;
}

.opacity-7 {
    opacity: 0.7;
}

.opacity-8 {
    opacity: 0.8;
}

.opacity-9 {
    opacity: 0.9;
}

.opacity-10 {
    opacity: 1;
}

/* Background light */
.bg-light {
    background-color: #FFF !important;
}

.bg-light-1 {
    background-color: #f9f9fb !important;
}

.bg-light-2 {
    background-color: #f8f8fa !important;
}

.bg-light-3 {
    background-color: #f5f5f5 !important;
}

.bg-light-4 {
    background-color: #eff0f2 !important;
}

.bg-light-5 {
    background-color: #ececec !important;
}

@media print {

    .table td,
    .table th {
        background-color: transparent !important;
    }

    .table td.bg-light,
    .table th.bg-light {
        background-color: #FFF !important;
    }

    .table td.bg-light-1,
    .table th.bg-light-1 {
        background-color: #f9f9fb !important;
    }

    .table td.bg-light-2,
    .table th.bg-light-2 {
        background-color: #f8f8fa !important;
    }

    .table td.bg-light-3,
    .table th.bg-light-3 {
        background-color: #f5f5f5 !important;
    }

    .table td.bg-light-4,
    .table th.bg-light-4 {
        background-color: #eff0f2 !important;
    }

    .table td.bg-light-5,
    .table th.bg-light-5 {
        background-color: #ececec !important;
    }
}

/* =================================== */
/*  Layouts
/* =================================== */
.invoice-container {
    margin: 0 auto;
    /* padding-top: 70px; */
    padding: 40px 30px 30px 40px;
    max-width: 850px;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    /* -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    -o-border-radius: 6px; */
    /* border-radius: 6px; */
}

/* =================================== */
/*  Extras
/* =================================== */
.bg-primary,
.badge-primary {
    background-color: #0071cc !important;
}

.bg-secondary {
    background-color: #0c2f55 !important;
}

.text-secondary {
    color: #0c2f55 !important;
}

.text-primary {
    color: #0071cc !important;
}

.btn-link {
    color: #0071cc;
}

.btn-link:hover {
    color: #0e7fd9 !important;
}

.border-primary {
    border-color: #0071cc !important;
}

.border-secondary {
    border-color: #0c2f55 !important;
}

.btn-primary {
    background-color: #0071cc;
    border-color: #0071cc;
}

.btn-primary:hover {
    background-color: #0e7fd9;
    border-color: #0e7fd9;
}

.btn-secondary {
    background-color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-primary {
    color: #0071cc;
    border-color: #0071cc;
}

.btn-outline-primary:hover {
    background-color: #0071cc;
    border-color: #0071cc;
    color: #fff;
}

.btn-outline-secondary {
    color: #0c2f55;
    border-color: #0c2f55;
}

.btn-outline-secondary:hover {
    background-color: #0c2f55;
    border-color: #0c2f55;
    color: #fff;
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #0071cc;
}

.page-item.active .page-link,
.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-control-input:checked~.custom-control-label:before {
    background-color: #0071cc;
    border-color: #0071cc;
}

.list-group-item.active {
    background-color: #0071cc;
    border-color: #0071cc;
}

.page-link {
    color: #0071cc;
}

.page-link:hover {
    color: #0e7fd9;
}

/* Pagination */
.page-link {
    border-color: #f4f4f4;
    border-radius: 0.25rem;
    margin: 0 0.3rem;
}

.page-item.disabled .page-link {
    border-color: #f4f4f4;
}

table,
th,
td {
    border: 1px solid black;
}

table {
    border-bottom: 0;
    border-left: 0;
}

td,
th {
    border-top: 0;
    border-right: 0;
}

.table tbody tr td {
    border-color: black !important
}

.table thead tr th {
    border-color: black !important
}
</style>
