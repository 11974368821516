<template>
<div class="container-fluid">
    <div class="form-head d-flex mb-3 align-items-start">
        <!-- <b-button variant="outline-info" @click="filterData = !filterData">
            Filter Data
            <b-icon-arrow-bar-down></b-icon-arrow-bar-down>
        </b-button> -->
        <div class="mr-auto d-none d-lg-block">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-block d-sm-flex">
                    <div>
                        <h4 class="card-title">Filter Report</h4>
                    </div>
                </div>
                <div class="card-body">
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text">Years</label>
                                <div class="input-group">
                                    <input v-model="inputShelter.year" type="number" class="form-control">
                                    <b-button :disabled="!inputShelter.year.length" size="sm" variant="info" @click="addYear">
                                        <b-icon icon="plus-circle" aria-hidden="true" animation="fade"></b-icon>
                                    </b-button>
                                </div>
                            </div>
                            <div class="form-group col-md-3 col-lg-3">
                                <label class="text">Product</label>
                                <div class="input-group">
                                    <v-select style="width:100%" label="name" :clearable="false" :filterable="false" :options="productsList" @input="selectedProduct" @search="onSearchProduct">
                                        <template slot="no-options"> type to search product... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                [{{ option.part_number}}] {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text">Customer</label>
                                <div class="input-group">
                                    <v-select style="width:100%" label="organization" :clearable="false" :filterable="false" :options="customerList" @input="selectedCustomer" @search="onSearchCustomer">
                                        <template slot="no-options"> type to search customer... </template>
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.organization }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.organization }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text">Sales</label>
                                <div class="input-group">
                                    <v-select style="width:100%" :clearable="false" :filterable="false" :options="salesList" label="first_name" @input="selectedSales">
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                <img :src='option.profile' style="width: 1.5rem;height: auto;" />
                                                {{ option.first_name + ' ' + option.last_name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                <img :src='option.profile' style="width: 1rem;height: auto;" />
                                                {{ option.first_name + ' ' + option.last_name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-3 col-lg-3">
                                <label class="text">Principle</label>
                                <div class="input-group">
                                    <v-select style="width:100%" :clearable="false" :filterable="false" :options="principleList" label="name" @input="selectedPrinciple" @search="onSearchPrinciple">
                                        <template slot="option" slot-scope="option">
                                            <div class="d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                        <template slot="selected-option" slot-scope="option">
                                            <div class="selected d-center">
                                                {{ option.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in paramQuery.years" :key="index">
                                        {{val}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeYear(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-3 col-lg-3">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in productShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeProduct(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in customerShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeCustomer(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in salesShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removeSales(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-3 col-lg-3">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(val, index) in principleShelter" :key="index">
                                        {{val.name}}
                                        <div class="pull-right">
                                            <b-button size="xs" variant="danger" @click="removePrinciple(index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text">Start Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.start_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-2 col-lg-2">
                                <label class="text">End Date</label>
                                <b-form-datepicker no-flip :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }" locale="id" v-model="paramQuery.end_date">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-12 col-lg-12">
                                <b-button variant="outline-info" @click="getData">
                                    Filter
                                </b-button>
                                <b-button class="ml-3" variant="outline-danger" @click="clearInput">
                                    Clear
                                </b-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h4 class="card-title">Purchase Report</h4>
                    </div>
                    <div class="card-action card-tabs mt-3 mt-sm-0">
                        <div class="input-group">
                            <!-- <div class="input-group-prepend">
                                <select class="input-group-text" v-model="paramQuery.column">
                                    <option value="" selected>All Column</option>
                                    <option v-for="(column, index) in columnRender" :key="index" :value="column.field"> {{ column.label }} </option>
                                </select>
                            </div>
                            <input type="text" v-on:keyup="onSearchChange" v-model="paramQuery.search" class="form-control w-50" placeholder="Search..."> -->
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <vue-good-table mode="remote" styleClass="display mb-4 dataTable no-footer" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                dropdownAllowAll: false,
                setCurrentPage: paramQuery.page,
                nextLabel: 'Next',
                prevLabel: 'Previous',
                setCurrentPage: paramQuery.page,
              }" :rows="rows" :columns="columns" compactMode>
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'sales_force'">
                                <div v-if="props.row.sales_force !== null">
                                    <img :src="props.row.sales_force.profile" class="rounded-circle mr-2" width="36" alt="Profile" />
                                    <span class="w-space-no">{{ props.row.sales_force.first_name }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                    </vue-good-table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "PurchaseReport",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Purchase Report | %s',
    },
    data() {
        return {
            columns: [{
                    label: "No.SO",
                    field: "no_so",
                    hidden: false,
                },
                {
                    label: "No.PO",
                    field: "no_po",
                    hidden: false,
                },
                {
                    label: "Product Name",
                    field: "product_name",
                    hidden: false,
                },
                {
                    label: "Part Number",
                    field: "part_number",
                    hidden: false,
                },
                {
                    label: "Principle",
                    field: "principle_name",
                    hidden: false,
                },
                {
                    label: "SO Date",
                    field: "date_so",
                    hidden: false,
                },
                {
                    label: "PO Date",
                    field: "date_po",
                    hidden: false,
                },
                {
                    label: "Due Date",
                    field: "due_date",
                    hidden: false,
                },
                {
                    label: "SO Qty",
                    field: "so_qty",
                    hidden: false,
                },
                {
                    label: "PO Qty",
                    field: "po_qty",
                    hidden: false,
                },
                {
                    label: "Receive Qty",
                    field: "receive_qty",
                    hidden: false,
                },
                {
                    label: "Sales",
                    field: "sales_force",
                    hidden: false,
                },
            ],
            filterData: false,
            rows: [],
            productsList: [],
            customerList: [],
            salesList: [],
            principleList: [],
            propsImport: {},
            totalRecords: 0,
            paramQuery: {
                page: 1,
                limit: 10,
                ascending: false,
                years: [],
                customers: [],
                sales: [],
                products: [],
                principles: [],
                start_date: "",
                end_date: "",
                column: ""
            },
            inputShelter: {
                year: [],
                customer: {},
                sales: {},
                product: {},
                principle: {},
            },
            productShelter: [],
            customerShelter: [],
            salesShelter: [],
            principleShelter: [],
        };
    },
    // computed: {
        // columnRender: function () {
        //     return this.columns.filter(i => i.field != 'action')
        // },
    // },
    async mounted() {
        await this.getData();
        await this.getSalesUsers()
    },
    methods: {

        containsObjectnonArray(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i] === obj) {
                    return true;
                }
            }
            return false;
        },
        containsObjectArray(obj, list) {
            var i;
            for (i = 0; i < list.length; i++) {
                if (list[i].id === obj.id) {
                    return true;
                }
            }
            return false;
        },

        addYear() {
            if (this.containsObjectnonArray(this.inputShelter.year, this.paramQuery.years)){
                return
            }
            this.paramQuery.years.push(this.inputShelter.year)
        },

        async removeYear(index) {
            this.paramQuery.years.splice(index, 1);
        },

        async removeProduct(index) {
            this.productShelter.splice(index, 1);
            this.paramQuery.products.splice(index, 1);
        },

        async removeCustomer(index) {
            this.customerShelter.splice(index, 1);
            this.paramQuery.customers.splice(index, 1);
        },

        async removeSales(index) {
            this.salesShelter.splice(index, 1);
            this.paramQuery.sales.splice(index, 1);
        },

        async removePrinciple(index) {
            this.principleShelter.splice(index, 1);
            this.paramQuery.principles.splice(index, 1);
        },

        clearInput() {
            this.paramQuery = {
                page: 1,
                limit: 10,
                ascending: false,
                expired_years: [],
                incoming_years: [],
                products: [],
                warehouses: [],
                column: ""
            }
            this.getData();
        },

        updateParams(newProps) {
            this.paramQuery = Object.assign({}, this.paramQuery, newProps);
        },

        onPageChange(params) {
            this.updateParams({
                page: params.currentPage
            });
            this.getData();
        },

        onPerPageChange(params) {
            this.updateParams({
                limit: params.currentPerPage,
                page: 1
            });
            this.getData();
        },

        onSortChange(params) {
            this.updateParams({
                column: params[0].field,
                ascending: params[0].type == "desc" ? false : true,
            });
            this.getData();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.getData();
        },

        // onSearchChange() {
        //     this.updateParams({
        //         page: 1
        //     });
        //     this.getData();
        // },

        async selectedProduct(selected) {
            this.inputShelter.product.id = selected.id
            this.inputShelter.product.name = selected.name
            if (this.containsObjectArray(this.inputShelter.product, this.productShelter)){
                return
            }
            this.productShelter.push({
                ...this.inputShelter.product
            })
            this.paramQuery.products.push(this.inputShelter.product.id)
        },

        async selectedCustomer(selected) {
            this.inputShelter.customer.id = selected.id
            this.inputShelter.customer.name = selected.organization
            if (this.containsObjectArray(this.inputShelter.customer, this.customerShelter)){
                return
            }
            this.customerShelter.push({
                ...this.inputShelter.customer
            })
            this.paramQuery.customers.push(this.inputShelter.customer.id)
        },

        async selectedSales(selected) {
            this.inputShelter.sales.id = selected.id
            this.inputShelter.sales.name = selected.first_name
            if (this.containsObjectArray(this.inputShelter.sales, this.salesShelter)){
                return
            }
            this.salesShelter.push({
                ...this.inputShelter.sales
            })
            this.paramQuery.sales.push(this.inputShelter.sales.id)
        },

        async selectedPrinciple(selected) {
            this.inputShelter.principle.id = selected.id
            this.inputShelter.principle.name = selected.name
             if (this.containsObjectArray(this.inputShelter.principle, this.principleShelter)){
                return
            }
            this.principleShelter.push({
                ...this.inputShelter.principle
            })
            this.paramQuery.principles.push(this.inputShelter.principle.id)
        },

        async onSearchProduct(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/product", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.productsList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async onSearchCustomer(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/customer", {
                        limit: 10,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.customerList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getSalesUsers() {
            try {
                await this.$store.dispatch("setLoading", true);
                let resp = await axios.get(
                    'user-service/auth/users-by-role?role=sales&is_access=1', {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.salesList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Sales Users", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Request Sales Users", error.message, "error");
            } finally {
                this.$store.dispatch("setLoading", false);
            }
        },

        async onSearchPrinciple(query) {
            try {
                if (query.length == 0) {
                    return false;
                }

                const resp = await axios.post(
                    "dental-erp-service/principle", {
                        limit: 25,
                        page: 1,
                        search: query,
                        column: "",
                        ascending: false,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error === false) {
                    this.principleList = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Request Principle", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request Principle ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },

        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.post("dental-erp-service/sales_order/sales/purchase", this.paramQuery, {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.totalRecords = resp.data.pagination.total;
                    this.rows = resp.data.data;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async changeColumn(column) {
            for (var i in this.columns) {
                if (this.columns[i].label == column.label) {
                    this.columns[i].hidden =
                        "hidden" in this.columns[i] ? !this.columns[i].hidden : false;
                    break;
                }
            }
        },
    },
};
</script>

<style scoped>
input,
textarea {
    border-width: initial !important;
}

div[class*="v-select vs--single vs--searchable"]>>>.vs__dropdown-toggle {
    height: 56px !important;
    border-radius: 0.6rem !important;
}
</style>
